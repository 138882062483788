export default function PostFlag({
  text,
  bgHex,
  textColor = "white",
}: {
  text: string;
  bgHex: string;
  textColor?: string;
}) {
  return (
    <div
      className="absolute z-1 text-white top-0 right-6 rounded-b px-2 py-0.5 text-10px tablet:text-12px font-bold"
      style={{ backgroundColor: bgHex, color: textColor }}
    >
      {text}
    </div>
  );
}
