interface FourFlowers2Props {
  className?: string;
}

export default function FourFlowers2({
  className,
}: FourFlowers2Props): JSX.Element {
  return (
    <div className={className}>
      <svg
        width="44"
        height="86"
        viewBox="0 0 44 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7456 16.2024L22.481 6.88578C22.3733 6.09476 22.8227 5.32697 23.5709 5.02015C24.3191 4.71332 25.1761 4.94706 25.6513 5.58705L33.5428 16.2019C33.9914 16.782 34.3663 17.415 34.66 18.0864C37.4269 24.8017 34.4939 32.5607 27.9236 35.9085C21.3525 39.2566 13.3514 37.0688 9.54571 30.8828C9.17436 30.2509 8.88335 29.5752 8.67769 28.8713L4.72641 16.2832C4.48913 15.5229 4.80455 14.6937 5.49254 14.2687C6.18091 13.8445 7.06582 13.9334 7.64248 14.4855L14.3059 20.8748L23.7456 16.2024Z"
          fill="#FF5D47"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7745 33.5779L14.8565 21.9632C13.0323 18.3848 12.0803 14.4239 12.0761 10.3959L12.0732 8.30319C12.0726 7.64259 12.4484 7.03439 13.045 6.73041C13.6408 6.42681 14.3537 6.48032 14.8878 6.86906L16.5807 8.10074C19.8366 10.4729 22.4803 13.5708 24.3038 17.1496L30.0875 28.5008C31.4386 31.0138 30.5787 34.1594 28.1212 35.6938C26.8601 36.4232 25.3589 36.6092 23.9688 36.2093C22.5784 35.8087 21.4234 34.8571 20.7745 33.5779Z"
          fill="#F5A623"
        />
        <path
          d="M29.2715 36.4248C29.2715 36.4248 41.7356 55.2761 42.9574 68.3051C44.1791 81.3341 42.4745 84.845 42.4745 84.845"
          stroke="#00A3AD"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.8635 72.4982C42.8635 72.4982 42.3302 65.0488 38.8144 57.4488C35.2986 49.8489 20.8012 41.5584 20.445 42.5022C20.0888 43.446 28.1335 56.7265 33.6802 60.4396C39.227 64.1527 42.8635 72.4982 42.8635 72.4982Z"
          fill="#00A3AD"
        />
      </svg>

      <svg
        width="39"
        height="121"
        viewBox="0 0 39 121"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5612 26.8953C13.5612 26.8953 -12.6945 83.9784 16.6519 120.414"
          stroke="#0C7076"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.54496 93.7971C2.54496 93.7971 0.633494 76.8697 6.37974 67.608C12.126 58.3463 24.8325 46.9393 25.633 48.4889C26.4335 50.0385 17.4796 66.9205 12.1227 77.9144C6.76585 88.9083 2.54496 93.7971 2.54496 93.7971Z"
          fill="#0C7076"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7164 13.5413C16.4395 11.5648 17.6183 9.78619 19.1574 8.34977L18.0862 3.96713C18.0154 3.67642 17.8041 3.44059 17.5229 3.33823C17.2421 3.23603 16.9283 3.28075 16.6871 3.4579L13.4628 5.82907L14.3632 17.2591L15.7164 13.5413Z"
          fill="#D1374A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6861 11.3913L31.6487 12.448L25.1246 19.0861L25.8062 17.2135C27.023 13.8704 26.8617 10.18 25.3576 6.95508L24.8758 5.92319C24.6672 5.4759 24.1347 5.2821 23.6873 5.49061L22.655 5.97144C19.439 7.48816 16.9479 10.2066 15.717 13.5413L14.3638 17.2591L13.4634 5.82909L13.3483 4.35297C13.0725 2.78346 12.2015 1.38029 10.9169 0.436356C10.6368 0.223777 10.2579 0.194184 9.94827 0.360331C9.63819 0.526329 9.45338 0.857829 9.47533 1.20881L9.80271 5.93098C9.91538 7.55404 9.68613 9.18278 9.12957 10.7119L6.60862 17.6382C4.5812 23.2085 7.45516 29.3683 13.0269 31.3962L15.5492 32.3143C21.1214 34.3424 27.282 31.4709 29.3094 25.9006L31.8304 18.9743C32.3873 17.4453 33.2587 16.0503 34.3883 14.8794L37.6744 11.4724C37.9168 11.2177 37.9883 10.8449 37.8575 10.5185C37.7271 10.1921 37.4178 9.97129 37.0666 9.95406C35.4758 9.85144 33.9066 10.3665 32.6861 11.3913Z"
          fill="#DE3A4F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1255 19.0863L31.6504 12.4485L31.7762 12.32L30.7017 8.55843C30.6312 8.26787 30.4196 8.03189 30.1384 7.92953C29.8575 7.82733 29.5437 7.87205 29.303 8.04935L26.3193 9.88953C26.8337 12.3313 26.6563 14.8676 25.8071 17.2136L25.1255 19.0863Z"
          fill="#D1374A"
        />
      </svg>

      <svg
        width="24"
        height="90"
        viewBox="0 0 24 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.14451 19.2924C4.35981 17.2644 6.33395 21.1374 7.40657 23.2633C8.47231 25.3801 11.0241 24.8261 11.511 21.5948C12.0117 18.3686 13.0763 17.0776 13.8974 17.7269C14.7209 18.3686 15.2997 20.8578 16.2862 19.38C17.2727 17.906 17.8457 15.4194 19.6533 16.253C21.2324 16.9745 22.0616 18.6804 22.4164 20.3347C22.4095 20.3476 22.4164 20.354 22.4233 20.3695C22.6921 22.7904 22.6209 25.2384 21.8755 27.2238C20.943 29.7259 18.7323 31.4821 16.1679 32.5965C16.3654 32.4716 16.5687 32.3376 16.7731 32.2074C22.1201 28.7017 18.6657 25.4755 17.2727 26.4869C15.8716 27.4983 15.3778 26.5822 14.5555 25.6546C13.7344 24.7385 13.0763 26.3915 12.339 29.1668C11.596 31.9253 8.87999 31.0994 7.8935 29.9926C6.90586 28.8846 3.70291 27.0409 2.88064 29.6229C2.05952 32.2074 5.67705 33.4971 5.67705 33.4971L5.63685 33.6389C2.94036 32.6571 1.09255 29.5726 0.375937 25.7873V25.7795C0.336891 24.2296 0.46092 20.8282 2.14451 19.2924Z"
          fill="#395E6E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.88042 29.6228C3.70269 27.0408 6.90563 28.8846 7.89327 29.9926C8.87977 31.0993 11.5958 31.9252 12.3388 29.1667C13.0761 26.3915 13.7341 24.7384 14.5553 25.6545C15.3775 26.5822 15.8714 27.4982 17.2724 26.4868C18.6655 25.4754 22.1199 28.7016 16.7729 32.2074C16.5684 32.3375 16.3652 32.4715 16.1676 32.5965C14.8056 33.1866 13.3448 33.5847 11.9173 33.8231C11.8301 33.6388 11.7715 33.5344 11.752 33.5022C11.6635 33.3566 11.4867 33.3141 11.3535 33.4146C11.2214 33.5125 11.1869 33.7135 11.2754 33.863C11.2765 33.8656 11.2892 33.8875 11.3041 33.9171C9.49073 34.1619 7.76465 34.14 6.4187 33.8681C6.14997 33.8153 5.88813 33.7342 5.63663 33.6388L5.67682 33.4971C5.67682 33.4971 2.0593 32.2074 2.88042 29.6228Z"
          fill="#00A3AD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4172 20.3347C22.0624 18.6803 21.2332 16.9745 19.6541 16.253C17.8465 15.4194 17.2735 17.906 16.287 19.3799C15.3005 20.8578 14.7217 18.3685 13.8983 17.7269C13.0771 17.0775 12.0125 18.3685 11.5118 21.5947C11.0249 24.8261 8.47311 25.3801 7.40738 23.2632C6.33475 21.1373 4.36061 17.2644 2.14531 19.2923C0.461725 20.8281 0.337698 24.2295 0.376743 25.7795C0.0207329 23.9126 -0.0573597 21.882 0.171177 19.8464C0.909611 13.3012 3.37527 9.7877 5.26327 8.87163C7.15702 7.94398 8.14351 10.6213 8.8808 11.7281C9.62268 12.8348 10.8549 12.2821 11.1041 11.174C11.1041 11.174 11.3533 9.51584 12.4191 9.24141C13.4848 8.96311 14.7217 11.0864 15.6289 10.2528C16.5293 9.4205 17.6811 8.31375 19.1603 10.1575C20.1215 11.3609 21.8832 15.802 22.4172 20.3347Z"
          fill="#E05955"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8295 4.65726C9.95327 4.34933 9.3492 1.7055 10.8295 0.536912C11.6541 -0.113738 13.1321 0.906687 13.2975 2.68856C13.4605 4.47302 11.7069 4.96519 10.8295 4.65726Z"
          fill="#E05955"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.45793 8.59216C8.52656 8.96322 7.32071 8.04072 7.86851 5.76409C8.0603 4.96527 9.51305 5.08767 10.0057 6.01146C10.4995 6.93268 10.3904 8.22367 9.45793 8.59216Z"
          fill="#E05955"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9946 6.25633C13.8439 5.88785 13.6245 3.73619 15.16 3.18217C16.1005 2.84332 16.9688 3.67306 16.9688 4.9035C16.9688 6.13393 16.1453 6.62482 14.9946 6.25633Z"
          fill="#E05955"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3703 79.0711C14.1945 79.6366 14.0058 80.0303 13.8094 80.328C13.0778 81.4361 12.2268 80.8548 11.4298 79.482C10.4196 77.7397 8.76516 70.9741 7.84616 68.1053C6.92715 65.2364 3.71062 60.0083 5.54864 58.7771C7.38665 57.5503 10.0513 61.1378 12.2576 67.6944C13.5155 71.4337 14.0251 74.6032 14.2304 76.6403C14.387 78.1764 14.3703 79.0711 14.3703 79.0711Z"
          fill="#00A3AD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8571 68.7892C13.8892 68.7519 13.9097 68.7305 13.9097 68.7305C13.9097 68.7305 15.6566 57.8648 18.3212 54.3804C20.9858 50.8974 23.8352 52.6396 23.2833 55.9193C22.7314 59.1991 18.6883 64.5274 17.9541 68.0118C17.2186 71.4948 14.1857 75.1869 13.5426 72.4182C13.5234 72.3337 13.5182 72.2679 13.5015 72.1877C13.0472 69.9788 13.6684 69.0153 13.8571 68.7892Z"
          fill="#00A3AD"
        />
        <path
          d="M12.1449 31.3424C12.1449 31.3424 14.5565 52.0798 14.3577 67.3101C14.1588 82.5405 13.2589 88.8756 13.2589 88.8756"
          stroke="#00A3AD"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}
