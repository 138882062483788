import cn from "classnames";
import { FC, forwardRef } from "react";

export interface CheckboxProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "id"
  > {
  id: string;
  label: string;
  helperText?: string;
  classes?: {
    root?: string;
    input?: string;
    label?: string;
    helperText?: string;
  };
}

const Checkbox: FC<CheckboxProps> = forwardRef(
  ({ classes, id, label, helperText, ...props }, ref) => {
    const helperTextId = `${id}-helper-text`;

    return (
      <div className={cn("relative flex items-start", classes?.root)}>
        <input
          {...props}
          id={id}
          ref={ref}
          aria-describedby={helperText ? helperTextId : undefined}
          type="checkbox"
          disabled={props.disabled}
          className={cn(
            "h-[1.1em] w-[1.1em] appearance-none rounded border-2 border-gray-200",
            "transition-colors transition-bg duration-100 ease-in-out checked:border-transparent checked:bg-teal-600",
            "hover:checked:bg-teal-600 focus:ring-teal-600 focus:checked:bg-teal-600",
            props.disabled
              ? "bg-gray-100 checked:bg-gray-300 hover:checked:bg-gray-300"
              : null,
            classes?.input
          )}
        />

        <div className="ml-3 text-[1em]">
          <label
            htmlFor={id}
            className={cn(
              classes?.label,
              "block font-semibold leading-[1.1em] text-gray-800",
              props.disabled ? "text-gray-400" : null
            )}
          >
            {label}
          </label>

          {helperText ? (
            <p
              id={helperTextId}
              className={cn("text-[1em] text-gray-600", classes?.helperText)}
            >
              {helperText}
            </p>
          ) : null}
        </div>
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
