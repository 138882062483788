import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import { DiscoverFeedPost } from "../types";

export const getPrivateDiscoverFeedQueryKey = (
  accessToken: string,
  topic?: string | null
) => ["community-discover-feed", accessToken, topic] as const;

const usePrivateDiscoverFeed = (
  topic?: string | null
): UseQueryResult<{ posts: DiscoverFeedPost[] }, Error> => {
  const { data: session } = useSession();

  return useQuery(
    getPrivateDiscoverFeedQueryKey(session?.accessToken, topic),
    ({ signal }) =>
      api
        .get<{ posts: DiscoverFeedPost[] }>("/v1/community/discover_feed", {
          signal,
          headers: {
            Authorization: session?.accessToken,
          },
          params: {
            topic,
          },
        })
        .then(({ data }) => data),
    {
      staleTime: Infinity,
      enabled: !!session?.accessToken,
    }
  );
};

export default usePrivateDiscoverFeed;
