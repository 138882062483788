import cn from "classnames";
import { UseFormRegister } from "react-hook-form";

import { FeedSettingsFormValues } from "./types";

interface Props {
  register: UseFormRegister<FeedSettingsFormValues>;
  isSubmitting?: boolean;
}

const ContentOptions = ({ register, isSubmitting }: Props) => {
  return (
    <fieldset className="mb-8">
      <legend className="block mb-4 font-bold text-gray-800 text-16px">
        Settings
      </legend>

      <div className="-space-y-px bg-white rounded-md">
        <label
          className={cn(
            "focus:outline-none relative flex cursor-pointer justify-between items-start space-x-2 py-2"
          )}
        >
          <input
            disabled={isSubmitting}
            className="w-4 h-4 text-indigo-600 rounded focus:ring-indigo-500"
            type="checkbox"
            {...register("showTriggerWarningPosts")}
          />

          <div className="flex flex-col flex-1">
            <span
              className={cn(
                "leading-none mb-1 block text-16px font-medium text-gray-900"
              )}
            >
              Trigger warning
            </span>
            <span className={cn("block text-14px text-gray-500")}>
              Posts tagged with trigger warning
            </span>
          </div>
        </label>

        <label
          className={cn(
            "focus:outline-none relative flex cursor-pointer items-start space-x-2 py-2"
          )}
        >
          <input
            disabled={isSubmitting}
            className="w-4 h-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
            type="checkbox"
            {...register("showMemberPosts")}
          />

          <div className="flex flex-col flex-1">
            <span
              className={cn(
                "leading-none mb-1 block text-16px font-medium text-gray-900"
              )}
            >
              Member posts
            </span>
            <span className={cn("block text-14px text-gray-500")}>
              Posts from the community members
            </span>
          </div>
        </label>
      </div>
    </fieldset>
  );
};

export default ContentOptions;
