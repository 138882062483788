import { Disclosure as HeadlessUIDisclosure } from "@headlessui/react";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";
import cn from "classnames";
import { ReactNode } from "react";

interface DisclosureProps {
  heading: string;
  body: ReactNode;
}

export default function Disclosure({
  heading,
  body,
}: DisclosureProps): JSX.Element {
  return (
    <div className="w-full rounded-lg bg-white py-4 px-6 shadow">
      <HeadlessUIDisclosure>
        {({ open }) => (
          <>
            <HeadlessUIDisclosure.Button className="flex w-full items-center justify-between">
              <span className="mr-2 block text-left text-16px font-bold laptop:text-20px">
                {heading}
              </span>
              <ChevronRightIcon
                className={cn(
                  "w-6 flex-shrink-0 transform stroke-current text-teal-600 transition-transform duration-300",
                  open && "rotate-90"
                )}
              />
            </HeadlessUIDisclosure.Button>

            <HeadlessUIDisclosure.Panel>
              <div className="mt-4 mb-2 text-left laptop:text-20px">{body}</div>
            </HeadlessUIDisclosure.Panel>
          </>
        )}
      </HeadlessUIDisclosure>
    </div>
  );
}
