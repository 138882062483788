import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import { api } from "@core/services/nocd-api";
import { Post, PostExt } from "@features/community-v2";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

import transformPost from "../transformers/transformPost";

export const getMyPostsQueryKey = (accessToken: string): string[] => [
  "user-posts",
  accessToken,
];

export const getPosts = (
  userId: number,
  accessToken?: string,
  signal?: AbortSignal
): Promise<PostExt[]> => {
  return api
    .get<Post[]>(`/v1/all_user_content?user_id=${userId}`, {
      headers: { Authorization: accessToken },
      signal,
    })
    .then(({ data: page }) => page.map((post) => transformPost(post)));
};

export const useMyPosts = (): UseQueryResult<PostExt[], AxiosError> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const { data: userData } = useUser();
  const userId = userData?.id;

  return useQuery(
    getMyPostsQueryKey(accessToken),
    ({ signal }) => getPosts(userId, accessToken, signal),
    {
      enabled: !!accessToken,
    }
  );
};
