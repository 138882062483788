import ArrowCircleRightIcon from "@heroicons/react/outline/ArrowCircleRightIcon";
import BanIcon from "@heroicons/react/outline/BanIcon";
import BookmarkIcon from "@heroicons/react/outline/BookmarkIcon";
import EyeOffIcon from "@heroicons/react/outline/EyeOffIcon";
import FlagIcon from "@heroicons/react/outline/FlagIcon";
import TrashIcon from "@heroicons/react/outline/TrashIcon";
import UserAddIcon from "@heroicons/react/outline/UserAddIcon";
import UserRemoveIcon from "@heroicons/react/outline/UserRemoveIcon";
import VolumeOffIcon from "@heroicons/react/outline/VolumeOffIcon";
import VolumeUpIcon from "@heroicons/react/outline/VolumeUpIcon";
import { LinkIcon } from "@heroicons/react/solid";
import cn from "classnames";

import { PostActionIds } from "../types";

interface PostActionIconProps {
  actionId: PostActionIds;
  isEnabled?: boolean;
  className?: string;
}

const PostActionIcon = ({
  actionId,
  className,
  isEnabled,
}: PostActionIconProps): JSX.Element => {
  switch (actionId) {
    case PostActionIds.MUTE_NOTIFICATIONS:
      return isEnabled ? (
        <VolumeOffIcon className={cn("w-6", className)} />
      ) : (
        <VolumeUpIcon className={cn("w-6", className)} />
      );
    case PostActionIds.BLOCK_USER:
      return <BanIcon className={cn("w-6", className)} />;
    case PostActionIds.FOLLOW_USER:
      return isEnabled ? (
        <UserAddIcon className={cn("w-6", className)} />
      ) : (
        <UserRemoveIcon className={cn("w-6", className)} />
      );
    case PostActionIds.DELETE_POST:
      return <TrashIcon className={cn("w-6", className)} />;
    case PostActionIds.REASSURANCE_SEEKING:
      return <EyeOffIcon className={cn("w-6", className)} />;
    case PostActionIds.HIDE_POST:
      return <EyeOffIcon className={cn("w-6", className)} />;
    case PostActionIds.FLAG_POST:
      return <FlagIcon className={cn("w-6", className)} />;
    case PostActionIds.BOOKMARK_POST:
    case PostActionIds.UNBOOKMARK_POST:
      return <BookmarkIcon className={cn("w-6", className)} />;
    case PostActionIds.COPY_LINK:
      return <LinkIcon className={cn("w-6", className)} />;
    default:
      return <ArrowCircleRightIcon className={cn("w-6", className)} />;
  }
};

export default PostActionIcon;
