import { XIcon } from "@heroicons/react/solid";
import Image from "next/image";
import { useCallback } from "react";

import { ImageData } from "../../types";

interface Props {
  image: ImageData;
  onRemove: (publicId: string) => void;
}
const UploadPreview = ({ image, onRemove }: Props) => {
  const { publicId } = image;
  const onClick = useCallback(() => {
    onRemove(publicId);
  }, [onRemove, publicId]);
  return (
    <div
      className="inline-flex relative justify-center items-center p-2 mt-1 border"
      key={image.publicId}
    >
      <Image
        src={image.url}
        width={42}
        height={42 / image.aspectRatio}
        alt=""
      />
      <button
        type="button"
        className="absolute -top-2 -left-2 p-0.5 bg-red-700 rounded-full"
        onClick={onClick}
      >
        <XIcon className="w-3 h-3 text-white" />
      </button>
    </div>
  );
};

export default UploadPreview;
