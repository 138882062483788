import axios from "axios";

interface Options {
  variables?: { [key: string]: string };
}

// We use the WPGraphQL plugin (https://www.wpgraphql.com/) to expose a GraphQL
// API from our WordPress website. The best way to construct the query you need
// is to log in to Kinsta, the hosting service we use for our WordPress website,
// and navigate to `GraphQL` => `GraphiQL IDE`. There you can explore the
// GraphQL schema and try out different queries.
export default function request<T>(
  query: string,
  { variables = {} }: Options = {},
  headers: { [key: string]: string } = {
    "Content-Type": "application/json",
    "gcdn-force": "1",
  }
): Promise<T> {
  return axios
    .post<{
      data: T;
      errors: string[];
    }>(
      process.env.NEXT_PUBLIC_WORDPRESS_GRAPHQL_API_URL,
      {
        query,
        variables,
      },
      {
        headers,
      }
    )
    .then((response) => response.data)
    .then(({ data, errors }) => {
      if (errors) {
        throw new Error(errors.join(`\n`));
      }

      return data;
    });
}
