import Button from "@core/ui/Button";
import Checkbox from "@core/ui/Checkbox";
import ModalSheet from "@core/ui/ModalSheet";
import { useForm } from "react-hook-form";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface ContentTypeFormState {
  journeyStories: boolean;
  topPosts: boolean;
  memberAchievements: boolean;
}

export const useContentTypeStore = create<ContentTypeFormState>()(
  persist(
    () => ({
      journeyStories: true,
      topPosts: true,
      memberAchievements: true,
    }),
    { name: "content-type-form", getStorage: () => sessionStorage }
  )
);

const ContentTypeForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const { journeyStories, topPosts, memberAchievements } =
    useContentTypeStore.getState();
  const { register, handleSubmit, formState, watch } =
    useForm<ContentTypeFormState>({
      defaultValues: {
        journeyStories,
        topPosts,
        memberAchievements,
      },
    });

  const { isSubmitting } = formState;
  const watchedJourneyStories = watch("journeyStories");
  const watchedTopPosts = watch("topPosts");
  const watchedMemberAchievements = watch("memberAchievements");

  const onSubmit = handleSubmit((values) => {
    useContentTypeStore.setState({
      journeyStories: values.journeyStories,
      topPosts: values.topPosts,
      memberAchievements: values.memberAchievements,
    });
    onSuccess();
  });

  return (
    <form onSubmit={onSubmit} className="space-y-4">
      <h2 className="text-24px font-bold">Content Types</h2>

      <Checkbox
        name="journeyStories"
        id="journeyStories"
        label="Journey Stories"
        disabled={!watchedMemberAchievements && !watchedTopPosts}
        {...register("journeyStories")}
        classes={{
          input: "disabled:cursor-not-allowed disabled:opacity-50",
          label: "disabled:opacity-50",
        }}
      />

      <Checkbox
        name="topPosts"
        id="topPosts"
        label="Top Posts"
        disabled={!watchedMemberAchievements && !watchedJourneyStories}
        {...register("topPosts")}
        classes={{
          input: "disabled:cursor-not-allowed disabled:opacity-50",
          label: "disabled:opacity-50",
        }}
      />

      <Checkbox
        name="memberAchievements"
        id="memberAchievements"
        label="Member Achievements"
        disabled={!watchedJourneyStories && !watchedTopPosts}
        {...register("memberAchievements")}
        classes={{
          input: "disabled:cursor-not-allowed disabled:opacity-50",
          label: "disabled:opacity-50",
        }}
      />

      <div className="flex justify-center pt-6">
        <Button
          color="indigo"
          loading={isSubmitting}
          type="submit"
          className="w-full text-16px font-bold"
          rounded="lg"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

interface ContentTypeDialogProps {
  onClose: () => void;
  isOpen: boolean;
}

const ContentTypeDialog = ({
  onClose,
  isOpen,
}: ContentTypeDialogProps): JSX.Element => {
  return (
    <ModalSheet onClose={onClose} isOpen={isOpen}>
      <ContentTypeForm onSuccess={onClose} />
    </ModalSheet>
  );
};

export default ContentTypeDialog;
