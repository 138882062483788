import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { findIndex } from "lodash/fp";
import {
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "react-query";

import { ImageData } from "../components/NewPost/types";
import transformPost from "../transformers/transformPost";
import {
  PaginatedPosts,
  Post,
  PostExt,
  PostPositionTypes,
  PostTypes,
} from "../types";
import { getInfinitePostsQueryKey } from "./useInfinitePosts";
import { useUserFeedSettings } from "./useUserFeedSettings";

interface MutationVariables {
  postBody?: string;
  hasTrigger: boolean;
  topicIds: string[];
  hasAcceptedReassurancePledge: boolean;
  title?: string;
  images?: ImageData[];
}

export const useMutateNewPost = (): UseMutationResult<
  PostExt,
  Error,
  MutationVariables
> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;
  const deviceId = session?.deviceId;

  const { feedSettingsByUser } = useUserFeedSettings();
  const userFeedSettings = feedSettingsByUser?.[session?.user?.id];
  const infinitePostsQueryKey = getInfinitePostsQueryKey(
    session?.accessToken,
    userFeedSettings
  );

  const queryClient = useQueryClient();

  return useMutation(
    ({
      postBody,
      hasTrigger,
      hasAcceptedReassurancePledge,
      topicIds,
      images,
      title,
    }) => {
      const postTypeData: Record<string, unknown> = {};
      let type = PostTypes.TEXT;

      if (Array.isArray(images) && images.length > 0) {
        if (Array.isArray(images) && images.length > 1) {
          type = PostTypes.MULTI_IMAGE;
          postTypeData.images = images.map(
            ({ url, publicId, aspectRatio }) => ({
              url,
              link: "",
              image_public_id: publicId,
              aspect_ratio: aspectRatio,
            })
          );
        } else {
          type = PostTypes.IMAGE_V1;

          const image = images[0];
          postTypeData.url = image.url;
          postTypeData.link = "";
          postTypeData.image_public_id = image.publicId;
          postTypeData.aspect_ratio = image.aspectRatio;
        }
      }

      return api
        .post<Post>(
          `/v1/posts`,
          {
            type,
            body: postBody || "",
            trigger_warning: Number(hasTrigger),
            topic_ids: topicIds,
            accepted_reassurance_pledge: hasAcceptedReassurancePledge,
            posting_title: title,
            post_type_data: postTypeData,
          },
          {
            headers: {
              "X-DeviceID": deviceId,
              Authorization: accessToken,
            },
          }
        )
        .then(({ data }) => transformPost(data));
    },
    {
      onSuccess: (newPost) => {
        // See if there's any data in the query cache
        const previousAllPosts = queryClient.getQueryData(
          infinitePostsQueryKey
        );

        if (previousAllPosts) {
          queryClient.setQueryData(
            infinitePostsQueryKey,
            (oldData: UseInfiniteQueryResult<PaginatedPosts>["data"]) => {
              const firstTraditionalPostIndex = findIndex(
                (post) => post.position_type === PostPositionTypes.TRADITIONAL,
                oldData.pages[0].data
              );

              oldData.pages[0].data.splice(
                firstTraditionalPostIndex === -1
                  ? 1
                  : firstTraditionalPostIndex,
                0,
                newPost
              );
              return oldData;
            }
          );
        }
      },
    }
  );
};
