import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { findIndex, findLastIndex } from "lodash/fp";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryResult,
} from "react-query";

import { Post, PostId } from "../types";
import { getPostQueryKey, GetPostResponse } from "./usePost";

interface MutationVariables {
  postId: PostId;
  body: string;
  replyId: PostId;
  /**
   * We support using the root post ID or any reply's post ID in the URL.
   * In both cases, the same UI will be shown.
   *
   * Example:
   * /community/posts/:rootPostId
   * /community/posts/:replyPostId
   *
   * The `postId` value passed into _this_ object will always need to be the
   * root post ID. However, we need to track the original post ID that was used
   * in the URL (and the query key), so we know which cache entry to
   * optimistically update.
   */
  queryKeyPostId: PostId;
}

export const useMutatePostReply = (): UseMutationResult<
  Post,
  Error,
  MutationVariables
> => {
  const { data: session } = useSession();
  const { accessToken, deviceId } = session ?? {};

  const queryClient = useQueryClient();

  return useMutation(
    ({ postId, replyId, body }) =>
      api
        .post<Post>(
          `/v1/posts/${postId}/reply`,
          {
            body,
            reply_id: replyId,
          },
          {
            headers: {
              "X-DeviceID": deviceId,
              Authorization: accessToken,
            },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: (newPost, { replyId, queryKeyPostId }) => {
        // See if there's any data in the query cache
        const previousPost = queryClient.getQueryData(
          getPostQueryKey(queryKeyPostId, accessToken)
        );

        if (previousPost) {
          queryClient.setQueryData(
            getPostQueryKey(queryKeyPostId, accessToken),
            (oldData: UseQueryResult<GetPostResponse>["data"]) => {
              if (newPost.db_depth === 1) {
                return {
                  ...oldData,
                  // Increment the number of replies
                  post: {
                    ...oldData.post,
                    num_replies: oldData.post.num_replies + 1,
                  },
                  // Add the reply to the thread
                  replies: oldData.replies.concat(newPost),
                };
              }

              const postRepliedToIndex = findIndex(
                (reply) => reply.id === replyId,
                oldData.replies
              );

              // Find the last depth 2 reply's index
              const lastReplyIndex = findLastIndex(
                (reply) =>
                  +reply.post_replied_to === +replyId && reply.db_depth === 2,
                oldData.replies
              );

              // Add the reply to the thread
              oldData.replies.splice(
                lastReplyIndex === -1
                  ? postRepliedToIndex + 1
                  : lastReplyIndex + 1,
                0,
                newPost
              );

              return oldData;
            }
          );
        }
      },
    }
  );
};
