import FourFlowers1 from "@core/illustrations/FourFlowers1";
import FourFlowers2 from "@core/illustrations/FourFlowers2";
import ContainedPageWithHeading from "@core/layout/ContainedPageWithHeading";
import Button from "@core/ui/Button";
import Guideline from "@core/ui/Disclosure";
import Link from "next/link";

const GUIDELINES = [
  {
    heading: "Encourage people to cope without reassurance.",
    body: "Because OCD causes such intense anxiety, it's normal to seek reassurance wherever you can. And it's natural to want to reassure someone in distress. But because reassurance actually makes it harder for people to get better, we should try to recognize when people are seeking reassurance and find other ways to help them instead.",
  },
  {
    heading: "Act like you're meeting people in real life.",
    body: "We tend to treat people worse just because we're on the internet. If something bothers you, take a deep breath first. Then do your best to remember they're also here to feel better.",
  },
  {
    heading:
      "If someone mentions suicide or self-harm, report it so we can help.",
    body: "Many people with OCD have thoughts about self-harm or suicide, and we can discuss those. But posts about suicidal ideation, attempted or completed suicide, and actual self-harm should be discussed with a professional. When you report them, we'll provide professional resources.",
  },
  {
    heading: "Don't give medical advice.",
    body: "Encourage people to seek further help, but please don't advise them to change medication or therapy. Everybody responds to treatment differently, and you never know what might happen.",
  },
  {
    heading: "Report anything that doesn't seem right.",
    body: "We all need to look out for one another. Help us out by reporting anything that seems dangerous, unkind, or out of place.",
  },
  {
    heading: "This isn't the place to advertise or promote.",
    body: "Let us know if you see anyone trying to sell products or services, recruit patients, promote their website, or get social media followers. We'll get rid of it so we can focus on helping each other find ways to feel better.",
  },
];

export function GuidelinesScreen(): JSX.Element {
  return (
    <ContainedPageWithHeading title="Community guidelines">
      <p className="opacity-80 laptop:text-20px">
        Welcome! To make sure this feed is helpful and comfortable for everyone,
        here are some important guidelines we all need to follow:{" "}
      </p>

      <div className="mt-6 grid gap-y-4">
        {GUIDELINES.map(({ heading, body }) => (
          <Guideline key={heading} heading={heading} body={body} />
        ))}
      </div>

      <div className="flex justify-center">
        <Link href="/community">
          <Button className="mt-12 text-24px laptop:mt-22">I accept</Button>
        </Link>
      </div>

      <FourFlowers2 className="absolute right-0 -bottom-5 hidden items-end laptop:flex" />

      <FourFlowers1 className="absolute left-0 -bottom-2 hidden items-end laptop:flex" />
    </ContainedPageWithHeading>
  );
}
