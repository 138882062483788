import { useSession } from "@core/hooks/useSession";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

import { usePostContentContext } from "./context";

interface Props {
  title: string;
  generatedTitle?: string;
}

const Wrapper: FC = ({ children }) => {
  const { variant, postId } = usePostContentContext();

  if (["feed", "discover", "related"].includes(variant)) {
    return (
      <Link href={`/community/posts/${postId}`} passHref>
        {children}
      </Link>
    );
  }

  return <>{children}</>;
};

const PostTitle: FC<Props> = ({ title, generatedTitle }) => {
  const { variant } = usePostContentContext();
  const { data: session } = useSession();
  switch (variant) {
    case "single": {
      if (!session && generatedTitle) {
        return (
          <div className="mb-2">
            <span>
              <Image
                src="/images/community/ai-generated-icon.svg"
                width="140"
                height="20"
                alt='Lightbulb with text that reads "Auto-generated title"'
              />
            </span>

            <h1 className="mt-1 mb-2 text-20px font-bold leading-tight text-[#1E293B]">
              {generatedTitle}
            </h1>

            <p className="text-gray-400 font-bold">{title}</p>
          </div>
        );
      }
      return <h1 className="block text-18px font-bold">{title}</h1>;
    }

    case "feed":
    case "related":
    case "discover":
      return (
        <Wrapper>
          <a className="block text-18px font-bold">{title}</a>
        </Wrapper>
      );
    default:
      return (
        <Wrapper>
          <p className="block text-18px font-bold">{title}</p>
        </Wrapper>
      );
  }
};

export default PostTitle;
