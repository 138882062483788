import { PostTypes } from "./types";

export const SUPPORTED_POST_TYPES = [
  PostTypes.IMAGE_V1,
  PostTypes.TEXT,
  PostTypes.VIDEO,
  PostTypes.LINK,
  PostTypes.THERAPY_SESSION_REVIEW,
  PostTypes.MULTI_IMAGE,
  PostTypes.POST_CAROUSEL,
  PostTypes.TEXT_REPLY,
];
