import { ArrowLeftIcon } from "@heroicons/react/solid";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import cn from "classnames";
import {
  MouseEvent,
  ReactChild,
  useCallback,
  useEffect,
  useState,
} from "react";

import styles from "./ImageLightbox.module.css";

interface Controlled {
  onClose: () => void;
  src?: string | null;
  children?: never;
}

interface Uncontrolled {
  onClose?: never;
  src: string;
  children: ReactChild;
}

type Props = Controlled | Uncontrolled;

const ImageLightbox = ({ src, onClose, children }: Props) => {
  const [open, setOpen] = useState(false);
  const isControlled = !children;

  // Automatically open or close it depending on the value of the src prop and if it is (un)controlled.
  useEffect(() => {
    if (isControlled) {
      setOpen(!!src);
    }
  }, [isControlled, setOpen, src]);

  // When the dialog is closed, make sure that the parent component is
  // notified. Currently, the unsets the currently selected image in the
  // MultiImagePreview component.
  useEffect(() => {
    if (!open && onClose) {
      onClose();
    }
  }, [open, onClose]);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
    },
    [setOpen]
  );

  // A lightbox may appear in a post preview in the community feed. The preview
  // has a onClick listener attached to it that takes the user to the single
  // post page when clicked. We don't want this to happen when something in the
  // lighbox is clicked so we just swallow the event and prevent it from
  // propagating.
  const swallowClickEvents = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
  }, []);

  return (
    <div role="presentation" onClick={swallowClickEvents}>
      <Dialog.Root modal open={open} onOpenChange={onOpenChange}>
        {children && <Dialog.Trigger>{children}</Dialog.Trigger>}
        <Dialog.Portal>
          <Dialog.Overlay
            className={cn("fixed inset-0 z-20 overflow-y-auto", styles.overlay)}
          >
            <div className="max-h-screen max-w-screen">
              <div className="flex w-screen max-w-full h-screen bg-black bg-opacity-90 tablet:p-6">
                <Dialog.Content
                  className={cn(
                    "relative flex flex-grow flex-col bg-black p-4 phone:p-7",
                    styles.content
                  )}
                >
                  <div className="absolute top-4 left-4 text-white phone:top-7 phone:left-7">
                    <Dialog.Close>
                      <ArrowLeftIcon className="w-5 h-5" />
                      <VisuallyHidden>Close</VisuallyHidden>
                    </Dialog.Close>
                  </div>

                  <div className="flex flex-grow justify-center py-8 px-4 m-auto max-w-full max-h-full">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={src}
                      alt="User post gallery item preview"
                      className="block object-scale-down"
                    />
                  </div>
                </Dialog.Content>
              </div>
            </div>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default ImageLightbox;
