import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import { FeedTypes } from "../types";

export const getQueryKey = (accessToken: string): string[] => [
  "feed-filter-options",
  accessToken,
];

interface TopicDTO {
  onboarding_selected: number;
  topic_description: string;
  topic_id: string;
  topic_image_url: string;
  topic_title: string;
}

export interface Topic {
  id: string;
  title: string;
  isSelected: boolean;
}

const transformTopicDTO = (data: TopicDTO): Topic => ({
  id: data.topic_id,
  title: data.topic_title,
  isSelected: Boolean(data.onboarding_selected),
});

interface CategoryDTO {
  category_title: string;
  topics: TopicDTO[];
}

export interface Category {
  title: string;
  topics: Topic[];
}

const transformCategoryDTO = (data: CategoryDTO): Category => ({
  title: data.category_title,
  topics: (data.topics ?? []).map(transformTopicDTO),
});

export const transformCategoryKey = (key: string): string =>
  key.replace(/ /g, "_").toUpperCase();

interface FeedOptionsDTO {
  feed_type: FeedTypes;
  categories: CategoryDTO[];
}

export type CategoryType = "subtypes" | "community_filters";
export type FeedCategories = Record<CategoryType, Category>;

export interface FeedOptions {
  feedType: FeedTypes;
  categories: FeedCategories;
}

export const useFeedOptions = (): UseQueryResult<FeedOptions, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session;

  return useQuery(getQueryKey(accessToken), async ({ signal }) =>
    api
      .get<FeedOptionsDTO>("/v3/community/filter_menu", {
        headers: { Authorization: accessToken },
        signal,
      })
      .then(({ data }) => ({
        feedType: data.feed_type,
        categories: data.categories.reduce((acc, item) => {
          const key = item.category_title.replace(/ /g, "_").toLowerCase();
          acc[key] = transformCategoryDTO(item);
          return acc;
        }, {}),
      }))
  );
};
