import { useSession } from "@core/hooks/useSession";
import Container from "@core/layout/Container";
import { useAuthenticationModal } from "@features/auth";
import { AdjustmentsIcon, SearchIcon, XIcon } from "@heroicons/react/solid";
import cn from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { forwardRef, useCallback, useState } from "react";

import ContentTypeDialog, { useContentTypeStore } from "./ContentTypeDialog";
import FeedSettingsDialog, {
  feedSettingsDialogStore,
} from "./FeedSettingsDialog";
import Header from "./Header";
import OcdJourneySidebar from "./JourneySidebar";
import SearchDialog from "./SearchDialog";
import TabSelector from "./TabSelector";

interface Props {
  hideFilters?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  hideSearch?: boolean;
  hideSidebar?: boolean;
  hideDiscover?: boolean;
  hideFavorites?: boolean;
  showContentTypeFilter?: boolean;
  children: React.ReactNode;
  topic?: string;
  clearTopic?: () => void;
  classes?: {
    container?: string;
  };
}

const PageWrapper = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      hideDiscover,
      hideFavorites,
      hideSearch,
      hideFilters,
      showContentTypeFilter,
      hideSidebar,
      topic,
      clearTopic,
      classes,
    },
    ref
  ) => {
    const { openModal: openAuthModal } = useAuthenticationModal();
    const { query } = useRouter();
    const { onOpen: openFeedSettingsDialog } = feedSettingsDialogStore();
    const [isContentFilterDialogOpen, setIsContentFilterDialogOpen] =
      useState(false);
    const contentTypeStore = useContentTypeStore();

    const getContentFilterNumber = useCallback(() => {
      const isFilterApplied = Object.values(contentTypeStore).some(
        (value) => !value
      );
      if (!isFilterApplied) {
        return null;
      }
      return Object.values(contentTypeStore).filter((value) => value).length;
    }, [contentTypeStore]);

    const { data: session } = useSession();
    const isAuthenticated = !!session?.accessToken;

    return (
      <div
        ref={ref}
        className="relative flex-1"
        style={{
          backgroundColor: "#F8FBFF",
        }}
      >
        <span
          style={{ height: "calc(100vh + 800px)" }}
          className="hidden tablet:block absolute top-0 w-full"
        >
          {isAuthenticated ? (
            <Image
              src="/images/community/background-gradients/desktop-auth.svg"
              layout="fill"
              objectFit="cover"
              objectPosition="left top"
              z-index={0}
            />
          ) : (
            <Image
              src="/images/community/background-gradients/desktop-unauth.svg"
              layout="fill"
              objectFit="cover"
              objectPosition="left top"
              z-index={0}
            />
          )}
        </span>

        <span
          style={{ height: "calc(100vh + 800px)" }}
          className="tablet:hidden absolute top-0 w-full"
        >
          {isAuthenticated ? (
            <Image
              src="/images/community/background-gradients/mobile-auth.svg"
              layout="fill"
              objectFit="cover"
              objectPosition="left top"
              z-index={0}
            />
          ) : (
            <Image
              src="/images/community/background-gradients/mobile-unauth.svg"
              layout="fill"
              objectFit="cover"
              objectPosition="left top"
              z-index={0}
            />
          )}
        </span>

        {session && <Header variant="authenticated" />}
        {!session && <Header variant="unauthenticated" />}

        <Container
          disablePadding
          className={cn(classes?.container, "px-2.5 pb-32 pt-5 z-1 relative")}
        >
          <div className="tablet:space-y-0 tablet:flex items-start justify-between mb-4">
            <div>
              <TabSelector
                variant={isAuthenticated ? "authenticated" : "unauthenticated"}
                hideDiscover={hideDiscover}
                hideFavorites={hideFavorites}
              />

              {topic ? (
                <button
                  type="button"
                  className="py-3 leading-none mt-4 px-4 text-14px tablet:text-16px font-bold text-teal-600 bg-teal-200 border border-teal-600 rounded-full flex items-center space-x-1"
                  onClick={() => clearTopic()}
                >
                  <span>{topic}</span>

                  <span>
                    <XIcon className="w-4 h-4" />
                  </span>
                </button>
              ) : null}
            </div>

            {showContentTypeFilter && isAuthenticated && (
              <button
                type="button"
                onClick={() => openFeedSettingsDialog()}
                className="mt-4 w-full tablet:w-auto ml-auto p-2 flex items-center space-x-1.5 border border-[#B3BAF7] bg-[#D8DFFC] text-indigo-600 rounded-lg"
              >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3421_21031)">
                      <path
                        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                        stroke="#545CEA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.0415 6.19962C11.4079 6.07749 11.5911 6.01641 11.713 6.05986C11.8189 6.09767 11.9024 6.1811 11.9402 6.28712C11.9836 6.40895 11.9226 6.59216 11.8004 6.95856L10.6848 10.3055C10.65 10.4099 10.6326 10.462 10.603 10.5054C10.5767 10.5438 10.5436 10.5769 10.5052 10.6032C10.4618 10.6328 10.4097 10.6502 10.3053 10.685L6.95832 11.8006C6.59192 11.9228 6.40871 11.9839 6.28688 11.9404C6.18086 11.9026 6.09743 11.8192 6.05962 11.7132C6.01617 11.5913 6.07724 11.4082 6.19937 11.0417L7.31504 7.69477C7.34982 7.59037 7.36721 7.53825 7.39685 7.49489C7.4231 7.45651 7.45627 7.42335 7.49464 7.3971C7.538 7.36746 7.59013 7.35006 7.69453 7.31528L11.0415 6.19962Z"
                        stroke="#545CEA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3421_21031">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>

                <span className="text-14px leading-none font-bold">
                  Content type{" "}
                  {getContentFilterNumber() && `(${getContentFilterNumber()})`}
                </span>
              </button>
            )}

            {(!hideFilters || !hideSearch) && isAuthenticated ? (
              <div className="mt-4 grid grid-cols-2 tablet:flex items-center gap-2.5">
                {!hideFilters && (
                  <button
                    className="w-full p-2 flex items-center space-x-1.5 border border-[#B3BAF7] bg-[#D8DFFC] text-indigo-600 rounded-lg"
                    onClick={() =>
                      isAuthenticated
                        ? openFeedSettingsDialog()
                        : openAuthModal()
                    }
                    type="button"
                  >
                    <AdjustmentsIcon className="h-6 w-6 tablet:h-4 tablet:w-4" />

                    <span className="text-14px leading-none font-bold">
                      Filter
                    </span>
                  </button>
                )}

                {!hideSearch && (
                  <Link
                    href={{ query: { ...query, smo: "1" } }}
                    shallow
                    replace
                  >
                    <a className="w-full p-2 flex items-center space-x-1.5 border border-[#B3BAF7] bg-[#D8DFFC] text-indigo-600 rounded-lg">
                      <SearchIcon className="h-6 w-6 tablet:h-4 tablet:w-4" />

                      <span className="text-14px leading-none font-bold">
                        Search
                      </span>
                    </a>
                  </Link>
                )}
              </div>
            ) : null}
          </div>

          {hideSidebar ? (
            children
          ) : (
            <div className="laptop:grid laptop:grid-cols-3 laptop:gap-x-6 pb-32">
              <div className="tablet:col-span-2">{children}</div>

              <div className="hidden laptop:block">
                <OcdJourneySidebar />
              </div>
            </div>
          )}
        </Container>

        {isAuthenticated ? <SearchDialog /> : null}

        <ContentTypeDialog
          isOpen={isContentFilterDialogOpen}
          onClose={() => setIsContentFilterDialogOpen(false)}
        />

        {isAuthenticated ? <FeedSettingsDialog /> : null}
      </div>
    );
  }
);

PageWrapper.displayName = "PageWrapper";

export default PageWrapper;
