import { useWarningOnExit } from "@core/hooks/useWarningOnExit";

interface UnsavedChangesWatcherCommunityProps {
  isDirty: boolean;
}

const UnsavedChangesWatcherCommunity = ({
  isDirty,
}: UnsavedChangesWatcherCommunityProps) => {
  useWarningOnExit(
    isDirty,
    "Not ready to share yet? This is a safe space and the community is here to support you. Just be mindful that if you proceed you’ll lose what you’ve made on this post."
  );

  return null;
};

export default UnsavedChangesWatcherCommunity;
