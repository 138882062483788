import useUser from "@core/hooks/useUser";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";

interface ConquerorPostStore {
  isConquerorPost: boolean;
  setIsConquerorPost: (isConquerorPost: boolean) => void;
  isConquerorPostEnabled: boolean;
  variant: "default" | "sustained";
}

const useIsConquerorPost = (): ConquerorPostStore => {
  const router = useRouter();
  const { data: user } = useUser();
  const isEnabled = useMemo(
    () =>
      (router?.query?.conqueror_post === "1" ||
        router?.query?.sustained_conqueror_post === "1") &&
      user?.userBadges?.includes("OCD_CONQUEROR"),
    [
      router?.query?.conqueror_post,
      router?.query?.sustained_conqueror_post,
      user?.userBadges,
    ]
  );
  const isSustained = useMemo(
    () => router?.query?.sustained_conqueror_post === "1",
    [router?.query?.sustained_conqueror_post]
  );
  const [isConquerorPost, setIsConquerorPost] = useState(isEnabled || false);

  return {
    isConquerorPost,
    setIsConquerorPost,
    isConquerorPostEnabled: isEnabled || false,
    variant: isSustained ? "sustained" : "default",
  };
};

export default useIsConquerorPost;
