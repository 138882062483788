import Button from "@core/ui/Button";
import Modal from "@core/ui/Modal";

const ExitPromptModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    size="extraSmall"
    className="mx-auto"
  >
    <div className=" p-4 bg-white rounded-lg text-center">
      <h2 className="text-lg font-semibold mb-4">Not ready to share yet?</h2>
      <p className="mb-9 text-gray-700">
        This is a safe space and the community is here to support you. Just be
        mindful that if you proceed you’ll lose what you’ve made on this post.
      </p>
      <div className="flex flex-col space-y-4">
        <Button onClick={onClose} variant="outlined" className="p-2">
          Continue editing
        </Button>
        <Button onClick={onConfirm} color="teal" className="p-2">
          Leave anyway
        </Button>
      </div>
    </div>
  </Modal>
);

export default ExitPromptModal;
