import { MouseEvent, useCallback, useMemo } from "react";

import ImagePreview from "./ImagePreview";

interface LinkPostContentProps {
  url?: string;
  title: string;
  description: string;

  imageUrl: string;
  imageAspectRatio: number;
  cloudinaryImageId?: string;
}

const LinkPreview = ({
  url,
  title,
  description,
  imageUrl,
  imageAspectRatio,
  cloudinaryImageId,
}: LinkPostContentProps): JSX.Element => {
  const baseUrl = useMemo(() => {
    if (url) {
      try {
        const { hostname } = new URL(url);
        return hostname;
      } catch (e) {
        // swallow error
      }
    }
    return null;
  }, [url]);

  const onClick = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
  }, []);

  return (
    <div className="space-y-4">
      <a
        href={url}
        className="flex max-w-[555.6px] flex-col overflow-hidden rounded-lg border"
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
      >
        <ImagePreview
          imageAspectRatio={imageAspectRatio}
          imageUrl={imageUrl}
          cloudinaryImageId={cloudinaryImageId}
          rounded={false}
        />

        <div className="py-2 px-4">
          <h2 className="mb-1 font-bold">{title}</h2>
          <p className="mb-2 text-14px text-gray-700">{description}</p>
          <p className="text-12px text-gray-600">{baseUrl}</p>
        </div>
      </a>
    </div>
  );
};

export default LinkPreview;
