import { create } from "zustand";

import { ImageData } from "./types";

export interface Store {
  title: string | null;
  body: string | null;
  hasTrigger: boolean;
  images: ImageData[] | null;
  hasAcceptedReassurancePledge: boolean;
  topicIds: string[] | null;
}

export const DEFAULT_STORE = {
  hasAcceptedReassurancePledge: false,
  title: null,
  body: null,
  hasTrigger: false,
  images: null,
  topicIds: null,
};

export const useStore = create<Store>(() => DEFAULT_STORE);

export const resetStore = () => {
  useStore.setState(DEFAULT_STORE, true);
};
