import Image from "next/image";
import Badge from "public/images/authentication-screen/badge.png";
import ConqeurorBg from "public/images/authentication-screen/conqueror-bg.png";
import Heart from "public/images/authentication-screen/heart.svg";
import Phone from "public/images/authentication-screen/phone.png";
import SpeechBubble from "public/images/authentication-screen/speech-bubble.svg";

import {
  AuthenticationForm,
  AuthenticationFormVariants,
} from "../../components/AuthenticationForm";
import styles from "./styles.module.css";

const BackgroundImage = ({
  variant,
}: {
  variant: AuthenticationFormVariants;
}) => {
  switch (variant) {
    case "conqueror":
      return (
        <div className="desktop:block desktop:col-span-5 relative  hidden">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <Image
            src={ConqeurorBg}
            alt=""
            placeholder="blur"
            aria-hidden="true"
            layout="fill"
            objectFit="cover"
          />
        </div>
      );
    default:
      return (
        <div className={styles.loginContainer}>
          <div className="relative px-6 text-center text-white">
            <div className="absolute left-0 top-20 h-full w-full">
              <h2 className="text-24px font-bold">Conquer OCD</h2>

              <p className="text-20px mt-3">
                Spend more time on what you love in life, and less time
                struggling with OCD symptoms.
              </p>
            </div>
          </div>

          <div className={styles.deviceAnimation}>
            <div className="absolute left-[150px] top-0">
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <Image src={SpeechBubble} alt="" aria-hidden="true" />
            </div>

            <div className="absolute bottom-60 right-[80px]">
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <Image src={Heart} alt="" aria-hidden="true" />
            </div>

            <div className="absolute right-[-50px] top-40">
              <Image
                src={Badge}
                height={153}
                width={153}
                alt=""
                aria-hidden="true"
              />
            </div>

            <Image
              src={Phone}
              width={538}
              height={595}
              alt=""
              aria-hidden="true"
            />
          </div>

          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="/images/authentication-screen/bottom-wave.svg"
            alt=""
            aria-hidden="true"
            className="absolute bottom-0 w-full"
          />

          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="/images/authentication-screen/logo.svg"
            alt="NOCD"
            className="absolute bottom-10 left-[50%] -translate-x-1/2 transform"
          />
        </div>
      );
  }
};

interface Props {
  variant?: AuthenticationFormVariants;
}

export function AuthenticationScreen({
  variant = "default",
}: Props): JSX.Element {
  return (
    <div className="desktop:grid-cols-8 max-w-100vw grid w-full overflow-hidden bg-white">
      <BackgroundImage variant={variant} />

      <div className="desktop:col-span-3 flex w-full flex-1 items-center justify-center bg-white">
        <div className="w-full max-w-[600px] px-6">
          <AuthenticationForm variant={variant} />
        </div>
      </div>
    </div>
  );
}
