import Button from "@core/ui/Button";
import { SUPPORTED_POST_TYPES } from "@features/community-v2/constants";
import { usePublicPosts } from "@features/community-v2/hooks/usePublicPosts";
import { PostTypes } from "@features/community-v2/types";
import Link from "next/link";
import { FC, useEffect } from "react";

import HighlightedReplyCard from "../HighlightedReplyCard";
import PostCard from "../PostCard";
import PostCarousel from "../PostContent/PostCarousel";
import LoadingFeed from "./LoadingFeed";
import { FeedProps } from "./types";

interface Props extends FeedProps {
  segmentId?: string;
  subtypeSlug?: string;
}

const PublicFeed: FC<Props> = ({
  segmentId,
  subtypeId,
  setError,
  subtypeSlug,
}) => {
  const {
    data: publicPosts,
    error,
    isLoading,
  } = usePublicPosts(segmentId, subtypeId);

  useEffect(() => {
    setError(error);
  }, [setError, error]);

  const pathname = subtypeSlug
    ? `/community/topics/${subtypeSlug}`
    : "/community/posts";

  if (isLoading) {
    return <LoadingFeed />;
  }

  return (
    <div className="space-y-3 !overflow-visible relative pb-16">
      {
        // Remove any post types that we don't have UI to support. The
        // API is also consumed by our iOS and Android apps. It's possible
        // that there will be some drift between clients.
        publicPosts?.data
          .filter(({ post_type }) => SUPPORTED_POST_TYPES.includes(post_type))
          .map((post) => {
            const isHighlightedReply =
              post.post_type === PostTypes.TEXT_REPLY &&
              post.post_type_data?.highlight_as_post;

            if (post.post_type === PostTypes.POST_CAROUSEL) {
              return (
                <div
                  id={`${post.id}`}
                  key={`${post.id}`}
                  className="bg-white py-6 px-5 rounded-2xl shadow-sm border border-gray-200"
                >
                  <PostCarousel items={post.post_type_data.carousel_items} />
                </div>
              );
            }

            if (isHighlightedReply) {
              return (
                <HighlightedReplyCard
                  key={post.id}
                  post={post}
                  rootPostId={post.id}
                  variant="feed"
                />
              );
            }

            return (
              <section
                id={`${post.id}`}
                key={`${post.id}`}
                className="bg-white py-6 px-5 rounded-2xl shadow-sm border border-gray-200"
              >
                <div className="flex items-start">
                  <div className="flex-grow">
                    <PostCard
                      id={String(post.id)}
                      onClickReply={{
                        type: "link",
                        href: `/community/posts/${post.id}`,
                      }}
                      post={post}
                      rootPostId={post.id}
                      variant="feed"
                    />
                  </div>
                </div>
              </section>
            );
          })
      }

      {
        // This is needed so search engines can paginate through the feed.
        publicPosts?.cursors?.next && (
          <div className="flex py-4 cursor-pointer tablet:justify-center">
            <Link
              href={{
                query: { segmentId: publicPosts.cursors.next },
                pathname,
              }}
              scroll
              passHref
            >
              <Button
                as="a"
                className="hidden items-center font-bold tablet:flex"
              >
                Go to next page
              </Button>
            </Link>

            <Link
              href={{
                query: { segmentId: publicPosts.cursors.next },
                pathname,
              }}
              scroll
              passHref
            >
              <Button
                as="a"
                variant="text"
                className="block items-center mx-auto font-bold tablet:hidden"
              >
                Go to next page
              </Button>
            </Link>
          </div>
        )
      }
    </div>
  );
};

export default PublicFeed;
