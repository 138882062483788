import Button from "@core/ui/Button";
import Modal from "@core/ui/Modal";
import { RadioGroup } from "@headlessui/react";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useMutatePostAction } from "../hooks/useMutatePostAction";
import { Post, PostActionIds, PostExt, RootPostId } from "../types";

interface FormValues {
  category: string;
}

interface FlagPostDialogProps {
  post: Post | PostExt;
  onClose: () => void;
  isOpen: boolean;
  rootPostId: RootPostId;
}

export default function FlagPostDialog({
  post,
  onClose,
  isOpen,
  rootPostId,
}: FlagPostDialogProps): JSX.Element {
  const { mutateAsync: performPostAction } = useMutatePostAction(rootPostId);

  const { formState, control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      category: null,
    },
  });
  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(({ category }) =>
    performPostAction({
      actionId: PostActionIds.FLAG_POST,
      postId: post.id,
      isEnabled: null,
      userId: null,
      category,
    })
      .then(() => toast.success("Post flagged successfully"))
      .catch((error: Error) => toast.error(error.message))
  );

  return (
    <Modal onClose={onClose} isOpen={isOpen} backgroundColor="gray">
      <Modal.CloseButton onClick={onClose} />
      <form id="subtypes_form" onSubmit={onSubmit} className="text-gray-900">
        <h2 className="mb-4 text-left text-24px font-bold">
          Why flag this post?
        </h2>

        <p className="mb-8 text-20px text-gray-800">
          Help us understand what&apos;s wrong with the post. Other community
          members can&apos;t see when you flag a post.
        </p>

        <Controller
          name="category"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} className="mb-8 -mt-2 -ml-2 flex flex-wrap">
              {[
                { value: "self_harm_suicide", label: "Self Harm or Suicide" },
                { value: "spam", label: "Spam" },
                { value: "offensive", label: "Offensive" },
              ].map(({ value, label }) => (
                <RadioGroup.Option
                  value={value}
                  key={label}
                  onClick={(e) => e.stopPropagation()}
                >
                  {({ checked }) => (
                    <div
                      className={cn(
                        "mx-2 my-2 cursor-pointer rounded-lg py-4 px-6 text-20px font-bold leading-none shadow focus:ring-2 focus:ring-teal-500 focus:ring-offset-2",
                        checked
                          ? "bg-teal-600 text-white"
                          : "bg-white text-gray-900"
                      )}
                    >
                      {label}
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          )}
        />

        <p className="text-red-700">
          If someone is in immediate danger, tell them to call their local
          emergency service or go to the hospital.
        </p>

        <Button
          className="mt-8 w-full text-20px"
          disabled={isSubmitting}
          loading={isSubmitting}
          type="submit"
          onClick={(e) => e.stopPropagation()}
        >
          Next
        </Button>
      </form>
    </Modal>
  );
}
