import { useMutateNewPost } from "@features/community-v2/hooks/useMutateNewPost";
import useTopics from "@features/community-v2/hooks/useTopics";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { resetStore, useStore } from "../store";
import { FormValues, ImageData } from "../types";

const schema = yup.object({
  images: yup
    .array()
    .nullable(true)
    .of(
      yup.object().shape({
        url: yup.string(),
        link: yup.string(),
        publicId: yup.string(),
        aspectRatio: yup.number(),
      })
    ),
  body: yup.string().when("images", {
    is: null,
    then: yup.string().required(),
  }),
  title: yup.string().required(),
  hasTrigger: yup.bool(),
});

const useNewPostController = (onSuccess: () => void) => {
  const [isSubtypeModalOpen, setIsSubtypeModalOpen] = useState(false);
  const [isCommunityGroupModalOpen, setIsCommunityGroupModalOpen] =
    useState(false);

  const { mutateAsync } = useMutateNewPost();

  const { data: topics } = useTopics();

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      body: "",
      title: "",
      hasTrigger: false,
      images: null,
    },
  });

  const { reset } = form;

  const createPost = useCallback(() => {
    // now we actually want to submit the data
    const formData = useStore.getState();

    return mutateAsync({
      postBody: formData.body,
      title: formData.title,
      images: formData.images,
      hasAcceptedReassurancePledge: formData.hasAcceptedReassurancePledge,
      hasTrigger: formData.hasTrigger,
      topicIds: formData.topicIds,
    })
      .then(() => {
        setIsCommunityGroupModalOpen(false);
        setIsSubtypeModalOpen(false);
        onSuccess();
        reset();
        resetStore();
        toast.success("Post created!");
      })
      .catch((error: Error) => {
        toast.error(error.message);
      });
  }, [mutateAsync, onSuccess, reset]);

  const handleImageUploaded = useCallback((images: ImageData[]) => {
    useStore.setState({ images });
  }, []);

  const handleImageDeleted = useCallback((publicId: string) => {
    const { images } = useStore.getState();
    if (Array.isArray(images) && images.length > 0) {
      useStore.setState({
        images: images.filter((item) => item.publicId !== publicId),
      });
      return;
    }
    useStore.setState({ images: null });
  }, []);

  const handleClickTopic = useCallback((topicId: string) => {
    const { topicIds } = useStore.getState();
    const selectedTopics = topicIds || [];
    if (selectedTopics.includes(topicId)) {
      useStore.setState({
        topicIds: topicIds.filter((id) => id !== topicId),
      });
      return;
    }
    useStore.setState({ topicIds: [...selectedTopics, topicId] });
  }, []);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      useStore.setState({
        body: values.body,
        title: values.title,
        hasTrigger: values.hasTrigger,
      });
      return createPost();
    },
    [createPost]
  );

  const handleSubmitConquerorPost = useCallback(
    (values: FormValues) => {
      useStore.setState({
        body: values.body,
        title: values.title,
        hasTrigger: values.hasTrigger,
        images: [
          {
            aspectRatio: 4.23943661972,
            publicId:
              "Community%20Post%20Assets/therapy_milestone_ocd_conqueror_post_banner_dz0obk",
            link: "https://res.cloudinary.com/nocdcloud/image/upload/v1682005159/Community%20Post%20Assets/therapy_milestone_ocd_conqueror_post_banner_dz0obk.png",
            url: "https://res.cloudinary.com/nocdcloud/image/upload/v1682005159/Community%20Post%20Assets/therapy_milestone_ocd_conqueror_post_banner_dz0obk.png",
          },
        ],
      });
      return createPost();
    },
    [createPost]
  );

  const handleSubmitSustainedConquerorPost = useCallback(
    (values: FormValues) => {
      useStore.setState({
        body: values.body,
        title: values.title,
        hasTrigger: values.hasTrigger,
        images: [
          {
            publicId: "",
            url: "https://assets.treatmyocd.com/images/banners/badge_sustained_conqueror.png",
            link: "https://assets.treatmyocd.com/images/banners/badge_sustained_conqueror.png",
            aspectRatio: 3.573,
          },
        ],
      });
      return createPost();
    },
    [createPost]
  );

  return {
    handleImageUploaded,
    handleImageDeleted,
    handleClickTopic,
    handleSubmit,
    form,
    topics: {
      subtypes: topics?.subtypes,
      communityGroups: topics?.communityFilters,
    },
    selectedTopicIds: useStore((state) => state.topicIds) || [],
    handleSubmitConquerorPost,
    handleSubmitSustainedConquerorPost,
    isSubtypeModalOpen,
    setIsSubtypeModalOpen,
    isCommunityGroupModalOpen,
    setIsCommunityGroupModalOpen,
  };
};

export default useNewPostController;
