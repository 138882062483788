import Modal, { useModal } from "@core/ui/Modal";

function ToggleButtonImage({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      className={className}
      width="56"
      height="24"
      viewBox="0 0 56 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="23"
        rx="11.5"
        fill="#19A3B9"
        stroke="#19A3B9"
      />
      <circle cx="43" cy="12" r="9" fill="white" />
      <path
        d="M14.5176 16.1465C17.0312 16.1465 18.5957 14.4648 18.5957 11.7754V11.7637C18.5957 9.08008 17.0254 7.39844 14.5176 7.39844C12.0156 7.39844 10.4395 9.07422 10.4395 11.7637V11.7754C10.4395 14.4648 12.0039 16.1465 14.5176 16.1465ZM14.5176 14.6465C13.1289 14.6465 12.2441 13.5449 12.2441 11.7754V11.7637C12.2441 9.99414 13.1348 8.89844 14.5176 8.89844C15.9062 8.89844 16.791 10 16.791 11.7637V11.7754C16.791 13.5273 15.9238 14.6465 14.5176 14.6465ZM19.7383 16H21.502V10.4629H21.5371L25.3691 16H26.9043V7.54492H25.1406V13.041H25.1055L21.2852 7.54492H19.7383V16Z"
        fill="white"
      />
    </svg>
  );
}

export default function TriggerWarningModal({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}): JSX.Element {
  const { focusRef } = useModal<HTMLDivElement>();

  return (
    <Modal
      className="w-full overflow-hidden !p-0 tablet:max-w-[37.5rem] tablet:rounded-lg"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div
        ref={focusRef}
        className="tablet:20px h-full min-h-[34rem] w-full bg-[#F8E8E5] p-8 leading-6 tablet:p-16"
      >
        <p className="text-20px font-bold">What is a trigger warning?</p>

        <p className="mt-4">
          A trigger warning lets people know that a post could cause intense
          distress. Discussions of violence, abuse, drugs, racism, and
          mistreatment based on someone&apos;s identity are common examples.
        </p>

        <p className="mt-4">
          <span className="font-bold">
            The NOCD community encourages people to NOT avoid triggers, and to
            practice tolerating distress—a principle of ERP
          </span>
          . But we understand that not everyone has had treatment or feels ready
          for this, so we&apos;ve added trigger warnings.
        </p>

        <p className="mt-4">
          If your post contains anything that could be especially distressing to
          other members of our community, you can add a trigger warning when you
          post:
        </p>

        <div className="mt-4 flex items-center rounded-lg bg-[#F4F4F4] px-4 py-1.5">
          <p>
            Does your post contain content that might be a trigger for other
            members?
          </p>

          <ToggleButtonImage className="ml-4 flex-shrink-0" />
        </div>
      </div>
      <Modal.CloseButton onClick={handleClose} />
    </Modal>
  );
}
