import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { Post, PostExt } from "@features/community-v2";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

import transformPost from "../transformers/transformPost";

export const getMyBookmarksQueryKey = (accessToken: string): string[] => [
  "my-bookmarks",
  accessToken,
];

export const getPosts = (
  accessToken?: string,
  signal?: AbortSignal
): Promise<PostExt[]> => {
  return api
    .get<Post[]>(
      `v1/community/bookmarks?feedType=posts&limit=2147483647&lessThan=2147483647`,
      {
        headers: { Authorization: accessToken },
        signal,
      }
    )
    .then(({ data }) => data.map((post) => transformPost(post)));
};

export const getReplies = (
  accessToken?: string,
  signal?: AbortSignal
): Promise<PostExt[]> => {
  return api
    .get<Post[]>(
      `v1/community/bookmarks?feedType=replies&limit=2147483647&lessThan=2147483647`,
      {
        headers: { Authorization: accessToken },
        signal,
      }
    )
    .then(({ data }) => data.map((post) => transformPost(post)));
};

export const useMyBookmarks = (): UseQueryResult<
  { posts: PostExt[]; replies: PostExt[] },
  AxiosError
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getMyBookmarksQueryKey(accessToken),
    async ({ signal }) => {
      const [posts, replies] = await Promise.all([
        getPosts(accessToken, signal),
        getReplies(accessToken, signal),
      ]);

      return { posts, replies };
    },

    {
      enabled: !!accessToken,
    }
  );
};
