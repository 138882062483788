import { Topic } from "@features/community-v2/hooks/useFeedOptions";
import cn from "classnames";
import { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";

import { FeedSettingsFormValues } from "./types";

interface Props {
  title: ReactNode;
  topics: Topic[];
  register: UseFormRegister<FeedSettingsFormValues>;
  isDisabled?: boolean;
}

const MyTopicsOptions = ({ title, topics, register, isDisabled }: Props) => {
  return (
    <fieldset className="mb-8">
      <legend className="block mb-2 font-bold text-gray-800 text-16px">
        {title}
      </legend>

      <div className="">
        {topics.map((topic) => (
          <label
            key={topic.id}
            className={cn(
              "focus:outline-none relative space-x-2 flex cursor-pointer items-center py-2",
              isDisabled && "!cursor-not-allowed opacity-60"
            )}
          >
            <input
              key={`${topic.id}-checkbox-${
                isDisabled ? "disabled" : "enabled"
              }`}
              disabled={isDisabled}
              className="w-4 h-4 text-indigo-600 disabled:text-gray-300 rounded border-gray-300 focus:ring-indigo-500 disabled:cursor-not-allowed"
              type="checkbox"
              {...register("topicIds")}
              value={topic.id}
            />

            <span className="block text-gray-900 text-16px">{topic.title}</span>
          </label>
        ))}
      </div>
    </fieldset>
  );
};

export default MyTopicsOptions;
