import cn from "classnames";
import { FC } from "react";

type Variant = "line" | "circle";
type Size = "default" | "custom";

interface Props {
  className?: string;
  variant?: Variant;
  size?: Size;
  bg?: string;
}

const Skeleton: FC<Props> = ({
  className,
  variant = "line",
  size = "default",
  bg = "bg-gray-400",
}) => {
  return (
    <div
      className={cn("animate-pulse", className, bg, {
        "rounded-lg": variant === "line",
        "h-2.5": variant === "line" && size === "default",

        "rounded-full": variant === "circle",
        "h-4 w-4": variant === "circle" && size === "default",
      })}
    />
  );
};

export default Skeleton;
