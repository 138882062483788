import { Router, useRouter } from "next/router";
import { useEffect } from "react";

export const useWarningOnExit = (
  shouldWarn: boolean,
  warningText?: string
): void => {
  const router = useRouter();
  const { asPath, replace, push, beforePopState } = router;
  const message = warningText || "Are you sure that you want to leave?";

  useEffect(() => {
    let isWarned = false;

    const routeChangeStart = (url: string) => {
      if (asPath !== url && shouldWarn && !isWarned) {
        isWarned = true;
        if (window.confirm(message)) {
          void push(url);
        } else {
          isWarned = false;
          Router.events.emit("routeChangeError");
          void replace(asPath, undefined, { shallow: true });
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw "Abort route change. Please ignore this error.";
        }
      }
    };

    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (shouldWarn && !isWarned) {
        const event = e || window.event;
        event.returnValue = message;
        return message;
      }
      return null;
    };

    Router.events.on("routeChangeStart", routeChangeStart);
    window.addEventListener("beforeunload", beforeUnload);
    beforePopState(({ url }) => {
      if (asPath !== url && shouldWarn && !isWarned) {
        isWarned = true;
        if (window.confirm(message)) {
          return true;
        }
        isWarned = false;
        window.history.pushState(null, "", url);
        replace(asPath, undefined, { shallow: true }) as unknown as void;
        return false;
      }
      return true;
    });

    return () => {
      Router.events.off("routeChangeStart", routeChangeStart);
      window.removeEventListener("beforeunload", beforeUnload);
      beforePopState(() => {
        return true;
      });
    };
  }, [message, shouldWarn, asPath, replace, beforePopState, push]);
};
