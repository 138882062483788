/* eslint-disable @next/next/no-img-element */
import cn from "classnames";
import { FC, MouseEvent, useCallback, useMemo } from "react";

import ImageLightbox from "../ImageLighbox";

interface Props {
  url?: string;
  imageUrl: string;
  imageAspectRatio: number;
  cloudinaryImageId?: string;
  title?: string;
  width?: number;
  rounded?: boolean;
}

const Wrapper: FC<{ url?: string }> = ({ url, children }) => {
  const onClick = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
  }, []);

  if (url) {
    return (
      <a
        href={url}
        className="block"
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  return <>{children}</>;
};

const ImagePreview = ({
  url,
  imageUrl,
  cloudinaryImageId,
  imageAspectRatio,
  title,
  width,
  rounded = true,
}: Props): JSX.Element => {
  const imagePreview = useMemo(() => {
    if (
      cloudinaryImageId &&
      imageUrl &&
      imageUrl.includes("res.cloudinary.com")
    ) {
      return `https://res.cloudinary.com/nocdcloud/image/upload/w_555,c_limit/v1530986461/${cloudinaryImageId}`;
    }
    return imageUrl;
  }, [cloudinaryImageId, imageUrl]);

  return (
    <div>
      <Wrapper url={url}>
        {url &&
        // There was a bug where link was getting set incorrectly when creating an image post
        // This is a fix to prevent the image from opening in a new tab
        url !== imageUrl ? (
          <img
            className={cn({ "rounded-lg": rounded })}
            loading="lazy"
            src={imagePreview}
            alt={title}
            width={width || 555.6}
            height={(width || 555.6) / (imageAspectRatio || 1)}
          />
        ) : (
          <ImageLightbox src={imagePreview}>
            <img
              className={cn({ "rounded-lg": rounded })}
              loading="lazy"
              src={imagePreview}
              alt={title}
              width={width || 555.6}
              height={(width || 555.6) / (imageAspectRatio || 1)}
            />
          </ImageLightbox>
        )}
      </Wrapper>
    </div>
  );
};

export default ImagePreview;
