import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import Button from "@core/ui/Button";
import Input from "@core/ui/Input";
import Modal from "@core/ui/Modal";
import ArrowLeftIcon from "@heroicons/react/outline/ArrowLeftIcon";
import SearchIcon from "@heroicons/react/outline/SearchIcon";
import { formatDistanceToNow } from "date-fns";
import htmr from "htmr";
import Linkify from "linkify-react";
import { omit } from "lodash/fp";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";

import { PostTypes } from "../types";

const SUPPORTED_POST_TYPES = [
  PostTypes.TEXT_REPLY,
  PostTypes.IMAGE_V1,
  PostTypes.TEXT,
  PostTypes.THERAPY_SESSION_REVIEW,
  PostTypes.VIDEO,
  PostTypes.LINK,
];

const humanizePostType = (postType: PostTypes): string => {
  switch (postType) {
    case PostTypes.TEXT_REPLY:
      return "Reply";
    case PostTypes.IMAGE_V1:
    case PostTypes.TEXT:
    case PostTypes.THERAPY_SESSION_REVIEW:
    case PostTypes.VIDEO:
    case PostTypes.LINK:
      return "Post";
    default:
      return postType;
  }
};

interface SearchResult {
  createdAt: string;
  highlighted: string;
  highlightedSnippet: string;
  postID: string;
  postRepliedTo: string;
  postType: PostTypes;
  threadID: string;
}

interface SearchCommunityResponse {
  results: SearchResult[];
}

const searchCommunity = (
  searchTerm: string,
  accessToken: string
): Promise<SearchResult[]> =>
  api
    .post<SearchCommunityResponse>(
      `/v1/community/search`,
      {
        term: searchTerm,
      },
      accessToken
        ? {
            headers: { Authorization: accessToken },
          }
        : undefined
    )
    .then(({ data }) => data.results);

const useCommunitySearch = (searchTerm: string, accessToken: string) => {
  return useQuery(
    ["community-search", searchTerm, accessToken],
    () => searchCommunity(searchTerm, accessToken),
    { enabled: !!searchTerm }
  );
};

interface SearchFormValues {
  searchTerm: string;
}

export default function SearchDialog(): JSX.Element {
  const router = useRouter();
  const isOpen = useMemo(() => router?.query.smo === "1", [router?.query?.smo]);
  const onClose = () =>
    router.replace({ query: omit(["smo", "q"], router?.query) }, undefined, {
      shallow: true,
    });

  const { data: session } = useSession() || {};

  const { data, isLoading } = useCommunitySearch(
    router.query.q as string,
    session?.accessToken
  );

  const { handleSubmit, register, formState } = useForm<SearchFormValues>({
    defaultValues: {
      searchTerm: router.query.q as string,
    },
  });
  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(({ searchTerm }) =>
    router.replace({ query: { ...router.query, q: searchTerm } })
  );

  return (
    <Modal onClose={onClose} isOpen={isOpen} disablePadding>
      <div className="min-h-96 pt-6">
        <form
          onSubmit={onSubmit}
          className="flex flex-1 items-center border-b px-6 pb-4"
        >
          <Button
            variant="text"
            color="gray"
            className="mr-4 tablet:hidden"
            onClick={onClose}
          >
            <ArrowLeftIcon className="h-6 w-6" />
          </Button>

          <Input
            backgroundColor="white"
            className="flex-1 text-18px"
            placeholder="What are you looking for?"
            {...register("searchTerm")}
          />

          <Button
            className="ml-4"
            variant="text"
            color="gray"
            type="submit"
            disabled={isSubmitting || isLoading}
            loading={isSubmitting || isLoading}
          >
            <SearchIcon className="h-6 w-6" />
          </Button>
        </form>

        <div className="divide-y overflow-y-auto rounded-b-xl tablet:h-96">
          {data ? (
            data
              .filter(({ postType }) => SUPPORTED_POST_TYPES.includes(postType))
              .map(
                ({
                  postType,
                  createdAt,
                  highlightedSnippet,
                  threadID,
                  postID,
                }) => (
                  <Link
                    href={{
                      pathname: `/community/posts/${threadID}`,
                      query: omit(["smo", "q"], router.query),
                    }}
                    key={[createdAt, postType, threadID, postID].join()}
                  >
                    <a className="block bg-white py-4 px-6 hover:bg-gray-100">
                      <div className="mb-2 flex justify-between">
                        <span className="text-14px font-bold uppercase text-gray-800">
                          {humanizePostType(postType)}
                        </span>

                        <span className="text-14px text-gray-500">
                          {formatDistanceToNow(new Date(createdAt))} ago
                        </span>
                      </div>

                      <div>
                        <Linkify<JSX.IntrinsicElements["div"], "div">
                          tagName="div"
                          className="whitespace-pre-wrap break-words"
                          options={{
                            attributes: {
                              onClick: (event) => {
                                event.preventDefault();
                              },
                            },
                            className: "break-all",
                            // Because this is user-generated content, we want to tell search engines not
                            // to crawl this link.
                            rel: "nofollow",
                          }}
                        >
                          {htmr(highlightedSnippet)}
                        </Linkify>
                      </div>
                    </a>
                  </Link>
                )
              )
          ) : (
            <div className="flex h-full w-full items-center justify-center px-8 py-32">
              <div className="flex flex-col items-center justify-center">
                <SearchIcon className="mb-2 h-12 w-12 text-gray-800" />
                <p className="text-18px text-gray-600">No results to show</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
