import { PostId } from "@features/community-v2";
import { createContext, FC, useContext, useMemo } from "react";

import { Variant } from "./types";

interface ContextValue {
  variant: Variant;
  postId: PostId;
}

const ContentContext = createContext<ContextValue>(null);

export const usePostContentContext = () =>
  useContext<ContextValue>(ContentContext);

export const ContextProvider: FC<{ variant?: Variant; postId: PostId }> = ({
  variant,
  postId,
  children,
}) => {
  const value = useMemo(() => ({ variant, postId }), [variant, postId]);

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
};
