import Loader from "@core/ui/Loader";
import PostCarousel from "@features/community-v2/components/PostContent/PostCarousel";
import { SUPPORTED_POST_TYPES } from "@features/community-v2/constants";
import { useInfinitePosts } from "@features/community-v2/hooks/useInfinitePosts";
import { PostTypes } from "@features/community-v2/types";
import cn from "classnames";
import { head } from "lodash/fp";
import { useRouter } from "next/router";
import { FC, useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import HighlightedReplyCard from "../HighlightedReplyCard";
import PostCard from "../PostCard";
import LoadingFeed from "./LoadingFeed";
import { FeedProps } from "./types";

const PersonalizedFeed: FC<FeedProps> = ({ setError, subtypeId }) => {
  const router = useRouter();
  // This query param is set to 1 if the user just completed onboarding
  const isFirstVisit = router.query.firstvisit;

  const {
    data: infinitePosts,
    fetchNextPage,
    hasNextPage,
    error,
    isLoading,
  } = useInfinitePosts(subtypeId);

  useEffect(() => {
    setError(error);
  }, [setError, error]);

  // Get the first pinned post id (this should be Stephen's post)
  const firstPostId = useMemo(
    () =>
      head(
        head(infinitePosts?.pages)?.data?.filter(({ post_type }) =>
          SUPPORTED_POST_TYPES.includes(post_type)
        )
      )?.id,
    [infinitePosts]
  );

  if (isLoading) {
    return <LoadingFeed />;
  }

  if (!isLoading && !infinitePosts?.pages.length) {
    return (
      <div>
        <div className="flex flex-col justify-center items-center">
          <svg
            width="98"
            height="68"
            viewBox="0 0 98 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.2139 24.5069C16.3913 24.0562 17.2086 23.0523 17.3491 21.8871C18.8365 9.40972 30.2679 0 43.9422 0C56.8753 0 67.9457 8.4743 70.2626 20.1508C70.5639 21.6679 71.9923 22.7754 73.6702 22.7914C87.0863 22.9196 98 33.0589 98 45.3932C98 57.8595 86.9698 68 73.4122 68H24.5878C11.0293 68 0 57.8595 0 45.3932C0 36.2434 5.97137 28.0452 15.2139 24.5069Z"
              fill="url(#paint0_linear_3401_20837)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M43.25 24.75C38.0033 24.75 33.75 29.0033 33.75 34.25C33.75 39.4967 38.0033 43.75 43.25 43.75C48.4967 43.75 52.75 39.4967 52.75 34.25C52.75 29.0033 48.4967 24.75 43.25 24.75ZM29 34.25C29 26.3799 35.3799 20 43.25 20C51.1201 20 57.5 26.3799 57.5 34.25C57.5 37.3276 56.5244 40.1773 54.8655 42.5068L66.3044 53.9456C67.2319 54.8731 67.2319 56.3769 66.3044 57.3044C65.3769 58.2319 63.8731 58.2319 62.9456 57.3044L51.5068 45.8655C49.1773 47.5244 46.3276 48.5 43.25 48.5C35.3799 48.5 29 42.1201 29 34.25Z"
              fill="#F8FBFF"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3401_20837"
                x1="7.00683e-07"
                y1="4.25"
                x2="92.2891"
                y2="32.8227"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F9EBD0" />
                <stop offset="0.505" stop-color="#E9DAFB" />
                <stop offset="1" stop-color="#D8DFFC" />
              </linearGradient>
            </defs>
          </svg>

          <h2 className="font-bold text-24px">We&apos;re Sorry!</h2>
          <p>Please adjust your filters to see more content.</p>
        </div>
      </div>
    );
  }

  return (
    <InfiniteScroll
      className="!overflow-visible space-y-3"
      dataLength={infinitePosts?.pages.length ?? 0}
      next={() => fetchNextPage()}
      hasMore={hasNextPage}
      loader={
        <div className="flex justify-center items-center py-16 text-indigo-600 text-24px">
          <Loader />
        </div>
      }
    >
      {infinitePosts?.pages.map((page) =>
        // Remove any post types that we don't have UI to support. The
        // API is also consumed by our iOS and Android apps. It's possible
        // that there will be some drift between clients.
        page.data
          .filter(({ post_type, post_type_data }) => {
            if (post_type === PostTypes.TEXT_REPLY) {
              if (post_type_data?.highlight_as_post === true) {
                return true;
              }
              return false;
            }
            return true;
          })
          .filter(({ post_type }) => SUPPORTED_POST_TYPES.includes(post_type))
          .map((post) => {
            // If it's the first pinned post and they just came from onboarding, we emphasize the post
            const isFirstPost = post.id === firstPostId;
            const shouldEmphasize = isFirstPost && isFirstVisit;
            const isHighlightedReply =
              post.post_type === PostTypes.TEXT_REPLY &&
              post.post_type_data?.highlight_as_post;

            if (post.post_type === PostTypes.POST_CAROUSEL) {
              return (
                <div
                  id={`${post.id}`}
                  key={`${post.id}`}
                  className={cn(
                    "bg-white py-6 px-5 rounded-2xl shadow-sm border border-gray-200",
                    shouldEmphasize &&
                      "border-[4px] border-teal-600 tablet:shadow-lg"
                  )}
                >
                  <PostCarousel items={post.post_type_data.carousel_items} />
                </div>
              );
            }

            if (isHighlightedReply) {
              return (
                <HighlightedReplyCard
                  key={post.id}
                  post={post}
                  rootPostId={post.id}
                  variant="feed"
                />
              );
            }

            return (
              <section
                id={`${post.id}`}
                key={`${post.id}`}
                className={cn(
                  "bg-white py-6 px-5 rounded-2xl shadow-sm border border-gray-200",
                  shouldEmphasize &&
                    "border-[4px] border-teal-600 tablet:shadow-lg"
                )}
              >
                <div className="flex items-start">
                  <div className="flex-grow">
                    <PostCard
                      id={String(post.id)}
                      onClickReply={{
                        type: "link",
                        href: `/community/posts/${post.id}`,
                      }}
                      post={post}
                      rootPostId={post.id}
                      variant="feed"
                    />
                  </div>
                </div>
              </section>
            );
          })
      )}
    </InfiniteScroll>
  );
};

export default PersonalizedFeed;
