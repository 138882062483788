import Image from "next/image";
import { MouseEvent, useCallback } from "react";

interface TherapySessionReviewPostContentProps {
  callToActionUrl: string;
  callToActionIconUrl: string;
  title: string;
  description: string;
  imageUrl: string;
}

const TherapyReview = ({
  callToActionUrl,
  callToActionIconUrl,
  title,
  description,
  imageUrl,
}: TherapySessionReviewPostContentProps): JSX.Element => {
  const onClick = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
  }, []);

  return (
    <div className="flex items-center space-x-2 rounded-lg bg-gray-100 py-2 px-3.5">
      <Image
        src={imageUrl}
        alt=""
        loading="lazy"
        className="flex-shrink-0"
        width={39}
        height={39}
      />

      <div className="flex-1">
        <h2 className="font-bold">{title}</h2>
        <p className="text-14px text-gray-700">{description}</p>
      </div>

      <a
        href={callToActionUrl}
        className="flex-shrink-0"
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
      >
        <Image
          src={callToActionIconUrl}
          alt={`Read more about ${title}`}
          width={20}
          height={20}
        />
      </a>
    </div>
  );
};

export default TherapyReview;
