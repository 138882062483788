interface FourFlowers1Props {
  className?: string;
}

export default function FourFlowers1({
  className,
}: FourFlowers1Props): JSX.Element {
  return (
    <div className={className}>
      <svg
        width="21"
        height="39"
        viewBox="0 0 21 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.05 34.409C10.05 34.409 9.69736 26.6992 17.151 23.9489C17.4106 23.8533 17.6961 24.0091 17.7614 24.2824C18.2275 26.244 19.2665 33.377 10.05 34.409Z"
          fill="#67C1B0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1614 30.8768C10.1614 30.8768 10.4343 24.9113 4.66704 22.7833C4.46643 22.7095 4.24506 22.8298 4.19463 23.0415C3.83383 24.5589 3.02991 30.078 10.1614 30.8768Z"
          fill="#67C1B0"
        />
        <path
          d="M10.047 16.0865C10.047 16.0865 11.1288 22.511 10.7682 28.0873C10.4076 33.6636 8.4696 37.8676 8.4696 37.8676"
          stroke="#00A3AD"
          strokeWidth="1.048"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1923 13.7458C13.6609 15.2486 12.3571 16.3751 10.7689 16.6269C11.0997 19.8339 14.2512 22.6212 16.2599 21.4386C18.53 20.1026 17.171 15.168 14.1923 13.7458Z"
          fill="#FDBD40"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.37754 8.76953C5.51125 6.17464 1.43195 5.37659 0.275387 7.41916C-1.02394 9.71388 2.51769 13.2917 5.80864 13.0301C5.51459 11.3625 6.16462 9.75773 7.37754 8.76953Z"
          fill="#FDBD40"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2518 8.38399C13.9287 5.49499 12.5785 0.932007 10.1022 0.932007C7.61744 0.932007 6.28808 5.50104 7.94783 8.3753C9.26903 7.61165 10.9062 7.59917 12.2518 8.38399Z"
          fill="#FDBD40"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9559 7.41925C18.7919 5.36346 14.6881 6.19024 12.8378 8.79231C14.0629 9.80735 14.6625 11.4129 14.3792 13.0264C17.6984 13.3284 21.2545 9.71208 19.9559 7.41925Z"
          fill="#FDBD40"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.9992 13.7537C2.98488 15.2103 1.71633 20.1252 3.94824 21.4385C5.96546 22.626 9.10772 19.8201 9.43885 16.6298C7.84511 16.3845 6.53466 15.2587 5.9992 13.7537Z"
          fill="#FDBD40"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0946 7.69946C7.63904 7.69946 5.64148 9.73636 5.64148 12.2398C5.64148 14.7432 7.63904 16.7801 10.0946 16.7801C12.5501 16.7801 14.548 14.7432 14.548 12.2398C14.548 9.73636 12.5501 7.69946 10.0946 7.69946Z"
          fill="#CE2526"
        />
      </svg>

      <svg
        width="31"
        height="83"
        viewBox="0 0 31 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4163 25.4174C15.4163 25.4174 28.3454 60.5058 20.2802 81.8467"
          stroke="#00A3AD"
          strokeWidth="1.072"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4215 57.3786C24.3298 60.9358 18.9884 61.7677 15.5449 58.7176C12.1013 55.6675 8.68415 54.4763 8.68415 54.4763C8.68415 54.4763 14.9388 49.3878 18.658 50.2986C22.3772 51.2094 24.5131 53.8214 24.4215 57.3786Z"
          fill="#00A3AD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2884 33.8041C13.602 33.8041 15.4777 31.7542 15.4777 29.2255V17.2067H4.4808C2.16711 17.2067 0.291504 19.2566 0.291504 21.7853C0.291504 28.4231 5.21496 33.8041 11.2884 33.8041Z"
          fill="#FF5A5A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6664 33.8041C17.3527 33.8041 15.4771 31.7542 15.4771 29.2255V17.2067H26.4739C28.7876 17.2067 30.6632 19.2566 30.6632 21.7853C30.6632 28.4231 25.7397 33.8041 19.6664 33.8041Z"
          fill="#FF464B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6664 0.609375C17.3527 0.609375 15.4771 2.65929 15.4771 5.18799V17.2068H26.4739C28.7876 17.2068 30.6632 15.1569 30.6632 12.6282C30.6632 5.99038 25.7397 0.609375 19.6664 0.609375Z"
          fill="#FF5A5A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2887 0.609375C13.6024 0.609375 15.478 2.65929 15.478 5.18799V17.2068H4.48117C2.16748 17.2068 0.29187 15.1569 0.29187 12.6282C0.29187 5.99038 5.21533 0.609375 11.2887 0.609375Z"
          fill="#FF464B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.478 10.3391C12.0074 10.3391 9.19409 13.4139 9.19409 17.207H15.478V10.3391Z"
          fill="#FF8C4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.761 17.207C21.761 13.4139 18.9476 10.3391 15.4771 10.3391V17.207H21.761Z"
          fill="#FFA555"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4771 24.0746C18.9476 24.0746 21.761 20.9998 21.761 17.2067H15.4771V24.0746Z"
          fill="#FF8C4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.19409 17.2067C9.19409 20.9998 12.0074 24.0746 15.478 24.0746V17.2067H9.19409Z"
          fill="#FFA555"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1286 9.9758C23.9529 9.72515 23.6241 9.67595 23.3945 9.86876L18.2785 14.1446L22.1908 8.55316C22.3665 8.30218 22.3228 7.94281 22.0928 7.75084C21.8635 7.55913 21.5347 7.60691 21.3587 7.85788L15.1032 16.7985L6.9227 23.6353C6.69283 23.8273 6.64911 24.1866 6.82476 24.4376C6.9278 24.5849 7.08352 24.6623 7.24125 24.6623C7.35194 24.6623 7.46394 24.6241 7.55885 24.5446L12.6749 20.2688L8.76259 25.8602C8.58688 26.1112 8.6306 26.4706 8.86053 26.6626C8.95539 26.7419 9.06738 26.7802 9.17831 26.7802C9.33581 26.7802 9.49153 26.7028 9.59457 26.5555L15.8501 17.6149L24.0305 10.7781C24.2605 10.5861 24.3043 10.2268 24.1286 9.9758Z"
          fill="#D7464B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0306 23.6354L15.8502 16.7986L9.59467 7.85788C9.41896 7.60691 9.09021 7.55913 8.86058 7.75084C8.63071 7.94288 8.58699 8.30218 8.76264 8.55316L12.6749 14.1446L7.5589 9.86876C7.32873 9.67595 6.99998 9.72509 6.8248 9.9758C6.64909 10.2268 6.69281 10.5861 6.92274 10.7781L15.1031 17.615L21.3587 26.5556C21.4617 26.7029 21.6174 26.7803 21.7749 26.7803C21.8858 26.7803 21.9978 26.742 22.0927 26.6626C22.3226 26.4706 22.3663 26.1113 22.1906 25.8603L18.2784 20.2689L23.3944 24.5448C23.4892 24.6241 23.6012 24.6624 23.712 24.6624C23.8697 24.6624 24.0254 24.585 24.1285 24.4377C24.3042 24.1868 24.2605 23.8274 24.0306 23.6354Z"
          fill="#CD4146"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1898 17.2068C20.1898 16.4362 19.7221 15.7918 19.0823 15.5746C19.3942 14.9266 19.308 14.1095 18.8094 13.5646C18.3108 13.0197 17.5632 12.9255 16.9703 13.2664C16.7715 12.5671 16.1819 12.0559 15.4768 12.0559C14.7718 12.0559 14.1822 12.5671 13.9834 13.2663C13.3905 12.9254 12.6429 13.0196 12.1443 13.5645C11.6457 14.1094 11.5595 14.9266 11.8714 15.5745C11.2316 15.7918 10.7639 16.4361 10.7639 17.2067C10.7639 17.9774 11.2316 18.6217 11.8713 18.8389C11.5595 19.4869 11.6457 20.304 12.1443 20.849C12.6428 21.3939 13.3905 21.4881 13.9833 21.1472C14.1821 21.8465 14.7717 22.3577 15.4768 22.3577C16.1819 22.3577 16.7715 21.8465 16.9702 21.1473C17.5631 21.4882 18.3107 21.394 18.8093 20.849C19.3079 20.3041 19.3941 19.4871 19.0822 18.839C19.7221 18.6218 20.1898 17.9774 20.1898 17.2068Z"
          fill="#91323C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3356 17.2069C12.3356 19.1006 13.745 20.6409 15.4776 20.6409C17.2101 20.6409 18.6196 19.1006 18.6196 17.2069C18.6196 15.3133 17.2102 13.7729 15.4776 13.7729C13.7449 13.7729 12.3356 15.3133 12.3356 17.2069ZM13.3832 17.2069C13.3832 15.9446 14.3229 14.9177 15.4778 14.9177C16.6328 14.9177 17.5724 15.9446 17.5724 17.2069C17.5724 18.4692 16.6328 19.4962 15.4778 19.4962C14.3229 19.4962 13.3832 18.4692 13.3832 17.2069Z"
          fill="#AF3A41"
        />
      </svg>

      <svg
        width="22"
        height="38"
        viewBox="0 0 22 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.74505 14.3126C5.80384 14.2269 5.91992 14.2034 6.0066 14.2641C6.00811 14.2641 6.0262 14.2778 6.03373 14.2831C6.50256 13.8363 6.85758 13.247 6.97818 12.5386C7.20204 11.2052 6.00208 9.42051 4.61293 8.27295C4.88503 9.63288 4.74257 11.0095 3.59839 11.4525C2.39767 11.9182 1.51202 10.6538 0.946716 9.37576C0.934656 10.8965 1.27987 12.9519 2.01326 14.2019C2.78284 15.508 4.54283 15.4526 5.75786 14.5212C5.7149 14.4583 5.69907 14.3794 5.74505 14.3126Z"
          fill="#578EA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.59808 11.4523C4.74227 11.0094 4.88472 9.63278 4.61262 8.27284C3.35613 7.2307 1.94437 6.71722 1.2969 7.54319C1.07606 7.82534 0.955456 8.51631 0.946411 9.37566C1.51172 10.6537 2.39737 11.918 3.59808 11.4523Z"
          fill="#E05955"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.93132 5.00539C9.28461 4.2894 9.59515 2.94311 10.1394 1.67419C9.37882 2.4046 8.63714 3.4475 8.20449 4.78923C7.26759 7.68734 9.42631 9.78451 11.6581 9.78451C13.8862 9.78451 15.5444 6.67402 14.9656 4.35462C14.8653 3.95112 14.7492 3.56809 14.6196 3.21616C14.0679 6.55267 11.1192 6.32285 9.93132 5.00539Z"
          fill="#578EA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.92942 5.00547C11.1173 6.32293 14.066 6.55275 14.6177 3.21624C14.0177 1.55292 13.1456 0.50547 12.3731 0.444793C11.911 0.410662 11.0133 0.831612 10.1375 1.67427C9.59325 2.94319 9.28271 4.28947 9.92942 5.00547Z"
          fill="#E05955"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7762 15.7105C14.8116 15.7833 14.786 15.86 14.7325 15.9168C14.8636 16.1125 15.0114 16.293 15.1817 16.4478C16.693 17.8214 19.7147 17.6772 20.7941 14.7799C20.9833 14.2634 21.1566 13.8113 21.3029 13.4116C19.9522 15.3374 17.4573 14.5721 17.198 12.8261C16.9078 10.8707 18.7085 10.2928 18.7085 10.2928L18.7605 10.2625C17.9623 10.3884 17.0766 10.6098 16.1917 10.9451C14.0918 11.7293 13.7451 14.0677 14.5599 15.6233C14.6458 15.6021 14.7355 15.6294 14.7762 15.7105Z"
          fill="#578EA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1975 12.8261C17.4568 14.5721 19.9517 15.3374 21.3024 13.4117C21.9763 11.5655 22.1444 10.8526 21.7306 10.4369C21.4019 10.1108 20.2321 10.0236 18.7601 10.2625L18.708 10.2928C18.708 10.2928 16.9074 10.8708 17.1975 12.8261Z"
          fill="#E05955"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2618 20.2211C11.2301 20.2439 11.1924 20.2568 11.1525 20.2568C11.1329 20.2568 11.1133 20.2537 11.0929 20.2469C10.9942 20.2135 10.9414 20.1066 10.9738 20.0072C11.0816 19.6819 11.2075 19.3823 11.3417 19.0971L11.9884 10.0174C11.9959 9.91201 11.9175 9.82175 11.8135 9.81417C11.7133 9.80279 11.6205 9.88471 11.6122 9.98937L10.7598 21.9626C10.8969 22.6839 10.9731 23.4537 10.9738 24.2714L11.2618 20.2211Z"
          fill="#00A3AD"
        />
        <path
          d="M11.2618 20.2211C11.2301 20.2439 11.1924 20.2568 11.1525 20.2568C11.1329 20.2568 11.1133 20.2537 11.0929 20.2469C10.9942 20.2135 10.9414 20.1066 10.9738 20.0072C11.0816 19.6819 11.2075 19.3823 11.3417 19.0971L11.9884 10.0174C11.9959 9.91201 11.9175 9.82175 11.8135 9.81417C11.7133 9.80279 11.6205 9.88471 11.6122 9.98937L10.7598 21.9626C10.8969 22.6839 10.9731 23.4537 10.9738 24.2714L11.2618 20.2211"
          stroke="#00A3AD"
          strokeWidth="1.35168"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3351 27.9295L9.74114 36.2711C9.73435 36.3758 9.81199 36.4661 9.916 36.4744C9.92128 36.4752 9.9258 36.4752 9.92957 36.4752C10.0276 36.4752 10.1105 36.3978 10.1173 36.2984L10.8077 26.6014C10.6637 26.5908 10.577 26.584 10.577 26.584C10.577 26.584 10.6705 26.4308 10.8371 26.1873L10.9471 24.6385C10.9155 24.6984 10.865 24.7454 10.7926 24.7454C10.7911 24.7454 10.7903 24.7454 10.7888 24.7454C10.6848 24.7432 10.6027 24.6567 10.6042 24.5513C10.6064 24.4496 10.5966 24.3563 10.5966 24.2562L10.3622 27.551C10.4542 27.7611 10.5047 27.8863 10.5047 27.8863C10.4489 27.9037 10.3916 27.9151 10.3351 27.9295Z"
          fill="#00A3AD"
        />
        <path
          d="M10.3351 27.9295L9.74114 36.2711C9.73435 36.3758 9.81199 36.4661 9.916 36.4744C9.92128 36.4752 9.9258 36.4752 9.92957 36.4752C10.0276 36.4752 10.1105 36.3978 10.1173 36.2984L10.8077 26.6014C10.6637 26.5908 10.577 26.584 10.577 26.584C10.577 26.584 10.6705 26.4308 10.8371 26.1873L10.9471 24.6385C10.9155 24.6984 10.865 24.7454 10.7926 24.7454C10.7911 24.7454 10.7903 24.7454 10.7888 24.7454C10.6848 24.7432 10.6027 24.6567 10.6042 24.5513C10.6064 24.4496 10.5966 24.3563 10.5966 24.2562L10.3622 27.551C10.4542 27.7611 10.5047 27.8863 10.5047 27.8863C10.4489 27.9037 10.3916 27.9151 10.3351 27.9295"
          stroke="#00A3AD"
          strokeWidth="1.35168"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.946 24.6384C10.9595 24.6133 10.9791 24.5906 10.9799 24.5602C10.9821 24.4609 10.9723 24.3691 10.9723 24.2713C10.9716 23.4536 10.8955 22.6838 10.7583 21.9625C9.83494 17.1037 6.28405 14.4635 6.0323 14.283C6.02476 14.2777 6.00667 14.264 6.00516 14.264C5.91848 14.2033 5.80241 14.2268 5.74361 14.3126C5.69764 14.3793 5.71346 14.4582 5.75643 14.5211C5.76924 14.5393 5.77226 14.5628 5.79185 14.5765C5.8416 14.6106 10.5804 17.9752 10.5955 24.2561C10.5955 24.3562 10.6053 24.4495 10.603 24.5511C10.6015 24.6566 10.6837 24.743 10.7877 24.7453C10.8638 24.7453 10.9143 24.6983 10.946 24.6384Z"
          fill="#00A3AD"
          stroke="#00A3AD"
          strokeWidth="1.35168"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0934 20.2469C11.1137 20.2537 11.1333 20.2567 11.1529 20.2567C11.1928 20.2567 11.2305 20.2438 11.2622 20.2211C11.2938 20.1991 11.3187 20.1665 11.3323 20.127C12.3009 17.2024 14.6676 15.9767 14.691 15.9653C14.7113 15.9547 14.7174 15.9327 14.7317 15.9168C14.7852 15.8599 14.8108 15.7833 14.7754 15.7105C14.7347 15.6293 14.645 15.602 14.5591 15.6232C14.547 15.6263 14.5342 15.6202 14.5221 15.6255C14.4302 15.6718 12.4833 16.6813 11.3421 19.097C11.2079 19.3822 11.082 19.6818 10.9743 20.0072C10.9419 20.1066 10.9946 20.2135 11.0934 20.2469Z"
          fill="#00A3AD"
        />
        <path
          d="M11.0934 20.2469C11.1137 20.2537 11.1333 20.2567 11.1529 20.2567C11.1928 20.2567 11.2305 20.2438 11.2622 20.2211C11.2938 20.1991 11.3187 20.1665 11.3323 20.127C12.3009 17.2024 14.6676 15.9767 14.691 15.9653C14.7113 15.9547 14.7174 15.9327 14.7317 15.9168C14.7852 15.8599 14.8108 15.7833 14.7754 15.7105C14.7347 15.6293 14.645 15.602 14.5591 15.6232C14.547 15.6263 14.5342 15.6202 14.5221 15.6255C14.4302 15.6718 12.4833 16.6813 11.3421 19.097C11.2079 19.3822 11.082 19.6818 10.9743 20.0072C10.9419 20.1066 10.9946 20.2135 11.0934 20.2469"
          stroke="#00A3AD"
          strokeWidth="1.35168"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.336 27.9295C10.3926 27.9151 10.4498 27.9037 10.5056 27.8862C10.5056 27.8862 10.4551 27.7611 10.3632 27.551C9.75188 26.1547 7.26 20.9182 4.82164 22.2387C1.58506 23.993 6.54168 28.8943 10.336 27.9295Z"
          fill="#00A3AD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8073 26.6015C11.7126 26.666 15.0908 26.8662 16.3322 26.3664C17.7719 25.7869 17.8435 23.0359 15.4692 22.7462C13.5502 22.5126 11.5384 25.1619 10.8367 26.1874C10.6701 26.4308 10.5767 26.5841 10.5767 26.5841C10.5767 26.5841 10.6633 26.5909 10.8073 26.6015Z"
          fill="#00A3AD"
        />
      </svg>

      <svg
        width="39"
        height="114"
        viewBox="0 0 39 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.0004 61.7919C37.1465 70.3518 20.3361 87.4113 10.2707 76.1617C7.19953 61.4135 30.9887 58.1658 39.0004 61.7919Z"
          fill="#67C1B0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.18248 79.4832C3.75499 79.001 3.80657 78.2692 4.29692 77.8493C4.76651 77.4464 15.9638 67.9459 29.8801 64.2134C30.508 64.0455 31.1549 64.4094 31.3263 65.0263C31.497 65.6432 31.1276 66.28 30.4997 66.4479C17.0992 70.0422 5.95472 79.5001 5.84384 79.5952C5.62031 79.7864 5.3446 79.8803 5.07068 79.8803C4.7422 79.8803 4.41491 79.7456 4.18248 79.4832Z"
          fill="#00A3AD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4362 14.0379C28.2725 14.0609 28.1011 14.0499 27.9323 13.9994C27.3214 13.8165 26.9816 13.1829 27.1731 12.5851L29.159 6.39521C29.3512 5.79791 30.0018 5.46132 30.6133 5.64469C31.2243 5.82815 31.564 6.46121 31.3719 7.05908L29.386 13.249C29.2469 13.6817 28.8672 13.9773 28.4362 14.0379Z"
          fill="#FF9216"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2296 12.4761C23.0658 12.4992 22.8938 12.4882 22.7257 12.4376C22.1147 12.2542 21.775 11.6211 21.9665 11.0233L23.953 4.83392C24.1451 4.23605 24.7957 3.89946 25.4067 4.08292C26.0176 4.26637 26.3573 4.89943 26.1658 5.49722L24.1794 11.6872C24.0402 12.1199 23.6605 12.4156 23.2296 12.4761Z"
          fill="#FF9216"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0206 10.9138C17.8563 10.9369 17.6848 10.9259 17.5161 10.8754C16.9051 10.6919 16.5654 10.0589 16.7575 9.46101L18.7435 3.27169C18.9355 2.67382 19.5855 2.33731 20.1971 2.52069C20.8081 2.70415 21.1478 3.3372 20.9557 3.93507L18.9698 10.125C18.8312 10.5576 18.451 10.8533 18.0206 10.9138Z"
          fill="#FF9216"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1642 8.09273L16.3332 7.56544L30.921 11.9405L30.7885 12.3533C29.543 16.2351 29.4295 20.3974 30.6267 24.2776C31.4049 26.8005 31.4139 29.5846 30.4709 32.2941C28.1679 38.9101 20.8685 42.6776 14.0069 40.7916C6.71752 38.7878 2.62438 31.323 4.88392 24.2804C5.70162 21.7329 7.23717 19.5961 9.20368 18.0157C12.4461 15.41 14.9099 11.9999 16.1642 8.09273Z"
          fill="#FF685F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4975 8.51372L19.3298 9.04141C18.0748 12.9481 16.154 16.5201 13.8627 19.4117C12.4733 21.1654 11.274 23.4033 10.4563 25.9503C8.237 32.8681 9.67141 39.452 13.6567 40.6886C6.58857 38.5462 2.663 31.2074 4.88504 24.28C5.70339 21.733 7.23828 19.5957 9.20472 18.0147C12.4488 15.4088 14.9123 11.9999 16.1658 8.09168L16.3354 7.56487L19.4975 8.51372Z"
          fill="#E05955"
        />
        <path
          d="M13.1491 40.9846C13.1491 40.9846 -3.86612 75.9345 6.47001 112.694"
          stroke="#00A3AD"
          strokeWidth="1.072"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
