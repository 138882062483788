import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import { PostAction, PostId } from "../types";

export const getQueryKey = (
  postId: PostId,
  accessToken: string
): [string, string, string] => ["post-actions", String(postId), accessToken];

export const usePostActions = (
  postId: PostId,
  options: UseQueryOptions<undefined, AxiosError, PostAction[]> = {}
): UseQueryResult<PostAction[], AxiosError> => {
  const { data: session } = useSession();

  return useQuery({
    queryKey: getQueryKey(postId, session?.accessToken),
    queryFn: ({ signal }) => {
      if (!session?.accessToken) {
        return Promise.resolve([
          {
            description: "I don't want notifications about this post",
            enabled: false,
            id: "Mute Notifications",
            image_url:
              "https://assets.treatmyocd.com/images/icons/icon_mute.png",
            link: null,
            post_id: null,
            refresh: false,
            success_message: "You've muted notifications for this thread",
            title: "Mute Notifications",
            toast_success_description:
              "You won't receive notification for this post",
            toast_success_title: "Post muted",
          },
          {
            description: "Notify me when this member posts",
            enabled: false,
            id: "Follow User",
            image_url:
              "https://assets.treatmyocd.com/images/icons/icon_follow.png",
            link: null,
            post_id: null,
            refresh: false,
            success_message: "You're now following this user",
            title: "Follow User",
            toast_success_description: "Member's posts will be prioritized",
            toast_success_title: "Following Ray NOCD 132",
          },
          {
            description: "Hide this member from my feed",
            enabled: false,
            id: "Block User",
            image_url:
              "https://assets.treatmyocd.com/images/icons/icon_block.png",
            link: null,
            post_id: null,
            refresh: true,
            success_message:
              "You have blocked this user, refresh your feed to see the changes",
            title: "Block User",
            toast_success_description:
              "Refresh your feed to see all the changes.",
            toast_success_title: "Ray NOCD 132 has been blocked",
          },
        ]);
      }
      return api
        .get<{ context_menu_items: PostAction[] }>(
          `/v1/community/post_context_menu?post_id=${postId}`,
          {
            headers: { Authorization: session?.accessToken },
            signal,
          }
        )
        .then(({ data }) => data.context_menu_items);
    },
    ...options,
  });
};
