import Skeleton from "@core/ui/Skeleton";
import { FC } from "react";

const PostSkeleton: FC = () => {
  return (
    <div className="bg-white py-6 px-5 rounded-2xl shadow-sm border border-gray-200">
      <div className="relative flex items-start space-x-4">
        <div className="flex flex-shrink-0 items-start ring-8 ring-white">
          <Skeleton
            variant="circle"
            className="h-[32px] w-[32px] tablet:h-[55px] tablet:w-[55px]"
          />
        </div>
        <div className="flex-1">
          <div className="mb-2 flex items-center space-x-2">
            <Skeleton className="h-3 w-[20%]" size="custom" />
            <Skeleton variant="circle" className="h-2 w-2" />
            <Skeleton className="h-3 w-8" />
          </div>
          <div className="mb-6 flex space-x-2">
            <Skeleton className="w-[5%]" />
            <Skeleton className="w-[5%]" />
            <Skeleton className="w-[5%]" />
          </div>

          <div className="space-y-4">
            <Skeleton size="custom" className="h-4 w-[30%]" />

            <div className="grid grid-cols-8 gap-2">
              <Skeleton />
              <Skeleton className="col-span-2" />
              <Skeleton className="col-span-4" />
              <Skeleton />

              <Skeleton className="col-span-3" />
              <Skeleton className="col-span-2" />
              <Skeleton className="col-span-3" />

              <Skeleton className="col-span-4" />
              <Skeleton className="col-span-4" />

              <Skeleton className="col-span-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSkeleton;
