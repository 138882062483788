import Image from "next/image";
import { FC } from "react";

interface JourneyCardProps {
  image: string;
  description: string;
  slug: string;
  authorName: string;
}

const JourneyCard: FC<JourneyCardProps> = ({
  image,
  description,
  slug,
  authorName,
}) => {
  return (
    <a
      href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/${slug}?useCommunityBackButton=1`}
    >
      <div className="relative w-full h-62 tablet:h-80">
        <Image
          src={image}
          alt=""
          aria-hidden="true"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />
      </div>

      <div className="p-4 tablet:p-6 space-y-2 bg-[#2E2F34] text-white">
        <p className="font-bold line-clamp-3 leading-tight">{description}</p>

        <span className="font-bold inline-block text-teal-600 text-12px tablet:text-14px">
          Read about {authorName?.split(" ")?.[0]}
          &apos;s journey &rarr;
        </span>
      </div>
    </a>
  );
};

export default JourneyCard;
