import Container from "@core/layout/Container";
import Button from "@core/ui/Button";
import { useAuthenticationModal } from "@features/auth";
import cn from "classnames";
import Image from "next/image";
import { FC } from "react";
import Marquee from "react-fast-marquee";

const CARDS = [
  {
    name: "Brooke M.",
    image:
      "https://origin-www.treatmyocd.com/wp-content/uploads//Brooke5-1463x2048.jpeg",
    slug: "/my-ocd-journey/my-ocd-journey",
  },
  {
    name: "Stacie Q.",
    image:
      "https://origin-www.treatmyocd.com/wp-content/uploads/image4-1-1024x683.jpg",
    slug: "/my-ocd-journey/from-childhood-sufferer-to-ocd-specialist",
  },
  {
    slug: "/my-ocd-journey/fighting-for-a-better-me",
    name: "Benjamin E.",
    image:
      "https://origin-www-qa.treatmyocd.com/wp-content/uploads/IMG_8827-scaled.jpeg",
  },
  {
    slug: "/my-ocd-journey/idiosyncrasies-navigating-an-obsessive-compulsive-mind",
    name: "Morgan E.",
    image:
      "https://origin-www-qa.treatmyocd.com/wp-content/uploads/Morganeastwood-scaled.jpeg",
  },
  {
    slug: "/my-ocd-journey/distressing-the-distress",
    name: "David K",
    image:
      "https://origin-www.treatmyocd.com/wp-content/uploads/DavidK-1-890x1024.jpg",
  },
  {
    slug: "/my-ocd-journey/ocd-is-just-radio-static-in-the-background",
    name: "Christian M.",
    image:
      "https://origin-www-qa.treatmyocd.com/wp-content/uploads/Christian1-scaled.jpeg",
  },
  {
    slug: "/my-ocd-journey/the-search-for-control",
    name: "Nicole K.",
    image:
      "https://origin-www-qa.treatmyocd.com/wp-content/uploads/Nicole2-scaled.jpg",
  },
  {
    slug: "/my-ocd-journey/harm-ocd-sexual-orientation-ocd-model-influencer-and-ocd-advocate",
    name: "Shaun F.",
    image:
      "https://origin-www-qa.treatmyocd.com/wp-content/uploads/unnamed-5.jpeg",
  },
];

interface HeroProps {
  variant: "authenticated" | "unauthenticated";
}

const Hero: FC<HeroProps> = ({ variant }) => {
  const { openModal } = useAuthenticationModal();
  return (
    <section
      className={cn(
        "relative font-poppins",
        variant === "unauthenticated" && "mb-24 tablet:mb-30"
      )}
    >
      <div className="flex justify-center px-6">
        <div className="font-sans text-14px text-center tablet:text-16px inline-block mx-auto px-4 tablet:px-8 py-2 rounded-b-lg bg-indigo-500 text-white relative z-1">
          If you&apos;re in crisis, please use these{" "}
          <a
            className="font-semibold underline"
            href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/emergency-resources`}
          >
            emergency resources
          </a>{" "}
          to find immediate help.
        </div>
      </div>

      <div
        className={cn(
          "px-6 z-1 relative",
          variant === "authenticated"
            ? "pt-10 pb-5 tablet:py-20"
            : "pt-12 tablet:pt-20 pb-59 tablet:pb-69"
        )}
      >
        <Container className="mx-auto text-center">
          <span className="text-[#6E76EE] bg-white rounded-full uppercase font-semibold text-14px tracking-wide py-2 px-6 inline-flex mb-4">
            NOCD Community
          </span>

          <h1 className="tablet:text-[3rem] text-[#373737] text-34px font-bold leading-tight">
            {variant === "authenticated"
              ? "Welcome to the NOCD Community"
              : "We are the faces of OCD"}
          </h1>

          <p className="tablet:text-18px mt-2 text-[#373737]">
            Kickstart your recovery journey with inspirational stories, guidance
            from experts, and a caring community of others working to conquer
            OCD.
          </p>

          {variant === "unauthenticated" ? (
            <div className="mt-4">
              <Button
                onClick={() =>
                  openModal({
                    redirectAfterAuthentication: false,
                    variant: "community",
                  })
                }
              >
                Join now
              </Button>
            </div>
          ) : null}
        </Container>
      </div>

      {variant === "unauthenticated" ? (
        <div className="z-1 w-full overflow-x-auto absolute bottom-0 transform translate-y-[33%]">
          <Marquee
            direction="left"
            gradient={false}
            pauseOnHover
            className="py-4"
          >
            {CARDS.map((card) => {
              return (
                <a
                  key={card.name}
                  href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}${card.slug}?useCommunityBackButton=1`}
                  className="inline-block rounded-2xl overflow-hidden flex-shrink-0 mx-4"
                >
                  <div className="relative w-[260px] h-[302px] tablet:w-[313px] tablet:h-[364px]">
                    <Image
                      src={card.image}
                      alt={card.name}
                      width={313}
                      height={364}
                      className="z-0"
                      layout="fill"
                      objectFit="cover"
                      objectPosition="top center"
                      priority
                    />

                    <div className="absolute bottom-4 left-4 z-1 text-white">
                      <p className="font-bold text-12px tablet:text-14px bg-black bg-opacity-30 rounded-lg p-1">
                        {card.name}
                      </p>
                    </div>
                  </div>
                </a>
              );
            })}
          </Marquee>
        </div>
      ) : null}
    </section>
  );
};

export default Hero;
