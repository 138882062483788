/* eslint-disable @next/next/no-img-element */
import "@egjs/react-flicking/dist/flicking.css";

import dynamic from "next/dynamic";
import {
  ForwardedRef,
  forwardRef,
  memo,
  MouseEvent,
  ReactElement,
  useCallback,
  useState,
} from "react";

import ImageLightbox from "../ImageLighbox";

const Flicking = dynamic(() => import("@egjs/react-flicking"));

interface ItemProps {
  src: string;
  openPreview: (src: string) => void;
}

const Item = forwardRef(
  (
    { src, openPreview }: ItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ): ReactElement => {
    const onClick = useCallback(
      (ev: MouseEvent) => {
        ev.stopPropagation();
        ev.preventDefault();
        openPreview(src);
      },
      [openPreview, src]
    );

    return (
      <div
        role="presentation"
        key={src}
        className="mr-4 mb-4 cursor-pointer h-[200px]"
        ref={ref}
        onClick={onClick}
      >
        <img
          src={src}
          alt={src}
          className="max-h-full rounded pointer-events-none"
        />
      </div>
    );
  }
);

Item.displayName = "Item";

const FlickingItem = memo(Item);

interface Props {
  images: Array<{ url: string; aspect_ratio: number; image_public_id: string }>;
}

const MultiImagePreview = ({ images }: Props): JSX.Element => {
  const [selected, setSelected] = useState<string | null>(null);

  const handleClick = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
  }, []);

  const openPreview = useCallback(
    (src: string) => {
      setSelected(src);
    },
    [setSelected]
  );

  const closePreview = useCallback(() => {
    setSelected(null);
  }, [setSelected]);

  return (
    <>
      <div onClick={handleClick} role="presentation">
        <Flicking
          align="prev"
          horizontal
          resizeOnContentsReady
          bound
          preventClickOnDrag
        >
          {images.map((item, index) => (
            <FlickingItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.url}-${index}`}
              src={item.url}
              openPreview={openPreview}
            />
          ))}
        </Flicking>
      </div>
      <ImageLightbox src={selected} onClose={closePreview} />
    </>
  );
};

export default MultiImagePreview;
