import { useEffect, useRef, useState } from "react";

type SetTimeoutReturn = ReturnType<typeof setTimeout>;

const useModalWithDelayedCallback = (initialDelay = 300) => {
  const [isOpen, setIsOpen] = useState(false);
  const callbackRef = useRef<(() => void) | null>(null);
  const timeoutIdRef = useRef<SetTimeoutReturn | null>(null);

  const clearExistingTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  };

  const open = () => {
    clearExistingTimeout();
    setIsOpen(true);
  };

  const close = (callback?: () => void, delay = initialDelay) => {
    setIsOpen(false);
    callbackRef.current = callback;
    clearExistingTimeout();
    if (callback) {
      timeoutIdRef.current = setTimeout(() => {
        callbackRef.current?.();
        callbackRef.current = null;
      }, delay);
    }
  };

  useEffect(() => {
    return () => {
      clearExistingTimeout();
    };
  }, []);

  return { isOpen, open, close };
};

export default useModalWithDelayedCallback;
