import request from "@core/services/wordpress-graphql-api/request";
import Loader from "@core/ui/Loader";
import gql from "fake-tag";
import React, { FC } from "react";
import { isBrowser } from "react-device-detect";
import { useQuery } from "react-query";
import Sticky from "react-stickynode";

import JourneyCard from "./JourneyCard";
import PostFlag from "./PostFlag";

export interface IStoryCard {
  title: string;
  author: string;
  image: string;
  imageWidth?: number;
  imageHeight?: number;
  slug: string;
  description: string;
}

export interface WPResponse {
  websiteOptions: {
    websiteOptionsCustomFields: {
      featuredMemberJourney: {
        title: string;
        slug: string;
        customFields: {
          consentGiven: boolean;
          authorName: string;
          description: string;
          tags: string;
          thumbnail: {
            sourceUrl: string;
            mediaDetails: {
              width: number;
              height: number;
            };
          };
        };
      };
    };
  };
  memberStories: {
    nodes: {
      title: string;
      slug: string;
      customFields: {
        consentGiven: boolean;
        authorName: string;
        description: string;
        tags: string;
        thumbnail: {
          sourceUrl: string;
          mediaDetails: {
            width: number;
            height: number;
          };
        };
      };
    }[];
  };
}

const fetchJourneys = () => {
  const isProduction = process.env.NODE_ENV === "production";

  const result = request<WPResponse>(
    gql`
      query PageProps {
        websiteOptions {
          websiteOptionsCustomFields {
            featuredMemberJourney {
              ... on MemberStory {
                id
                title
                slug
                customFields {
                  authorName
                  consentGiven
                  description
                  tags
                  thumbnail {
                    sourceUrl(size: LARGE)
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
        memberStories(first: 2) {
          nodes {
            title
            slug
            customFields {
              authorName
              consentGiven
              description
              tags
              thumbnail {
                sourceUrl(size: LARGE)
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
    `,
    {},
    {
      "Content-Type": "application/json",
    }
  ).then(({ memberStories, websiteOptions }) => {
    const mappedStories = memberStories.nodes
      // Only show stories that have consent given in production
      .filter((story) =>
        isProduction ? story?.customFields?.consentGiven : true
      )
      .map((story) => {
        const aspectRatio =
          story.customFields.thumbnail?.mediaDetails?.width /
          story.customFields.thumbnail?.mediaDetails?.height;
        const newWidth = 300;
        const newHeight = newWidth / aspectRatio;

        return {
          title: story.title,
          slug: story.slug,
          author: story.customFields.authorName,
          description: story.customFields.description,
          tags: story.customFields.tags,
          image: story.customFields.thumbnail.sourceUrl,
          imageWidth: newWidth,
          imageHeight: newHeight,
        };
      });

    const rawFeaturedStory =
      websiteOptions?.websiteOptionsCustomFields?.featuredMemberJourney;

    const aspectRatio =
      rawFeaturedStory.customFields.thumbnail?.mediaDetails?.width /
      rawFeaturedStory.customFields.thumbnail?.mediaDetails?.height;
    const newWidth = 425;
    const newHeight = newWidth / aspectRatio;

    const featuredStory = {
      title: rawFeaturedStory.title,
      slug: rawFeaturedStory.slug,
      author: rawFeaturedStory.customFields.authorName,
      description: rawFeaturedStory.customFields.description,
      tags: rawFeaturedStory.customFields.tags,
      image: rawFeaturedStory.customFields.thumbnail.sourceUrl,
      imageWidth: newWidth,
      imageHeight: newHeight,
    };

    const stories = [
      featuredStory,
      ...mappedStories
        .filter((story) => story.slug !== featuredStory.slug)
        .slice(0, 1),
    ];

    return stories;
  });

  return result;
};

const OcdJourneySidebar: FC = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["ocd-journeys"],
    queryFn: () => fetchJourneys(),
    staleTime: Infinity,
  });

  if (isLoading) {
    return (
      <div className="flex text-32px text-indigo-600 justify-center items-center py-12">
        <Loader />
      </div>
    );
  }

  return (
    <Sticky top={100} enabled={isBrowser}>
      <div className="grid gap-4">
        {data?.map((post) => (
          <section
            key={post.slug}
            className="border border-gray-200 relative -mx-4 bg-white tablet:mx-0 rounded-2xl tablet:shadow-sm overflow-hidden"
          >
            <JourneyCard
              image={post.image}
              description={post.description}
              slug={post.slug}
              authorName={post.author}
            />

            <PostFlag bgHex="#6f76ef" text="OCD Journey Stories" />
          </section>
        ))}
      </div>
    </Sticky>
  );
};

export default React.memo(OcdJourneySidebar);
