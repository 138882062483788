import cn from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Fragment, useMemo } from "react";

interface TabSelectorProps {
  variant: "authenticated" | "unauthenticated";
  hideDiscover?: boolean;
  hideFavorites?: boolean;
}

const TabSelector: FC<TabSelectorProps> = ({
  variant,
  hideDiscover,
  hideFavorites,
}) => {
  const { pathname, push, isReady } = useRouter();

  const tabs = useMemo(
    () =>
      [
        !hideDiscover
          ? {
              name: "Discover",
              href: "/community",
              current: pathname === "/community",
              authenticatedOnly: false,
            }
          : null,
        {
          name: "Discussion",
          href: "/community/posts",
          current: pathname === "/community/posts",
          authenticatedOnly: false,
        },
        !hideFavorites
          ? {
              name: "Favorites",
              href: "/community/favorites",
              current: pathname === "/community/favorites",
              authenticatedOnly: true,
            }
          : null,
      ].filter(Boolean),
    [pathname, hideDiscover, hideFavorites]
  );

  return (
    <>
      <div className="tablet:hidden w-full border-red-600">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>

        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 tablet:text-sm"
          defaultValue={pathname}
          onChange={(e) =>
            push(e.target.value, undefined, {
              scroll: false,
            })
          }
          key={`tab-selector-${isReady ? "1" : "0"}`}
        >
          {tabs
            .filter((tab) => {
              if (variant === "authenticated") {
                return true;
              }

              return !tab.authenticatedOnly;
            })
            .map((tab) => (
              <option key={tab.name} value={tab.href}>
                {tab.name}
              </option>
            ))}
        </select>
      </div>

      <div className="hidden tablet:block">
        <nav className="flex" aria-label="Tabs">
          {tabs
            .filter((tab) => {
              if (variant === "authenticated") {
                return true;
              }

              return !tab.authenticatedOnly;
            })
            .map((tab, index) => (
              <Fragment key={tab.name}>
                {index !== 0 ? (
                  <div className="border border-gray-200 h-5 mt-2.5 mx-2" />
                ) : null}

                <Link href={tab.href} passHref>
                  <a
                    aria-current={tab.current ? "page" : undefined}
                    className={cn(
                      tab.current
                        ? "border-teal-500 border-b-2 text-teal-600 font-bold"
                        : "text-gray-900 hover:text-black",
                      "whitespace-nowrap py-2.5 text-16px font-medium mx-1"
                    )}
                  >
                    {tab.name}
                  </a>
                </Link>
              </Fragment>
            ))}
        </nav>
      </div>
    </>
  );
};

export default TabSelector;
