import useForkRef from "@core/hooks/useForkRef";
import { CloudinaryResponse } from "@core/types";
import Loader from "@core/ui/Loader";
import axios from "axios";
import cn from "classnames";
import Image, { StaticImageData } from "next/image";
import LandscapeImage from "public/images/community/image-landscape.svg";
import {
  ChangeEvent,
  FC,
  Ref,
  RefCallback,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import { Control, useController } from "react-hook-form";
import toast from "react-hot-toast";

import { FormValues, ImageData } from "../../types";
import UploadPreview from "./UploadPreview";

const uploadImage = async (file: File) => {
  const formData = new FormData();

  formData.append("upload_preset", "user_generated");
  formData.append("tags", "browser_upload");
  formData.append("file", file);

  const response = await axios.post<CloudinaryResponse>(
    `https://api.cloudinary.com/v1_1/nocdcloud/upload`,
    formData,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    }
  );

  const { data } = response;

  return {
    url: data.secure_url,
    publicId: data.public_id,
    link: data.url,
    aspectRatio: data.width / data.height,
  };
};

interface UseUploadImagesArgs {
  onSuccess: (images: ImageData[]) => void;
  onRemove: (publicId: string) => void;
  control: Control;
}

export interface UseUploadImagesReturn {
  ref: RefCallback<HTMLInputElement>;
  controlRef: Ref<HTMLInputElement>;
  upload: (files: FileList) => void;
  isUploading: boolean;
  images: ImageData[] | null;
  remove: (publicId: string) => void;
}

type UseUploadImages = (args: UseUploadImagesArgs) => UseUploadImagesReturn;

const useUploadImages: UseUploadImages = ({ onSuccess, onRemove, control }) => {
  const [isUploading, setIsUploading] = useState(false);

  const { field } = useController<FormValues, "images">({
    name: "images",
    control,
  });
  const { onChange: setImages, ref: inputRef, value: images } = field;

  const controlRef = useRef<HTMLInputElement>();
  const ref = useForkRef(inputRef, controlRef);

  const upload = useCallback(
    (files: FileList) => {
      const acceptedFileExtensions = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg",
      ];

      const fileArray = Array.from(files);

      if (fileArray.length === 0) {
        return;
      }

      const toUpload = Array.from(files).filter((file) =>
        acceptedFileExtensions.includes(file.type)
      );

      if (toUpload.length === 0) {
        toast.error("unsupported file types");
        return;
      }

      setIsUploading(true);

      const promises = toUpload.map(uploadImage);

      Promise.all(promises)
        .then((uploadedImages) => {
          onSuccess(uploadedImages);
          setImages(uploadedImages);
        })
        .catch(({ message }: Error) => toast.error(message))
        .finally(() => setIsUploading(false));
    },
    [onSuccess, setIsUploading, setImages]
  );

  const remove = useCallback(
    (publicId: string) => {
      if (Array.isArray(images)) {
        const next = images.filter(
          (item: ImageData) => item.publicId !== publicId
        );
        if (next.length > 0) {
          setImages(next);
        } else {
          setImages(null);
        }
      }
      onRemove(publicId);
    },
    [setImages, images, onRemove]
  );

  return {
    ref,
    controlRef,
    upload,
    isUploading,
    images,
    remove,
  };
};

function isRefObject<T>(ref: Ref<T>): ref is RefObject<T> {
  if (ref && (ref as RefObject<T>).current) {
    return true;
  }
  return false;
}

export interface Props {
  onUpload: (images: ImageData[]) => void;
  onDeleteUpload: (publicId: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  control: Control;
}

const UploadImage: FC<Props> = ({ onUpload, onDeleteUpload, control }) => {
  const { images, isUploading, ref, controlRef, upload, remove } =
    useUploadImages({
      onSuccess: onUpload,
      onRemove: onDeleteUpload,
      control,
    });

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      void upload(ev.target.files);
    },
    [upload]
  );

  const handleClick = useCallback(() => {
    if (isRefObject(controlRef)) {
      controlRef.current.click();
    }
  }, [controlRef]);

  return (
    <div>
      {images ? (
        <div className="flex space-x-2">
          {images.map((image) => (
            <UploadPreview
              key={image.publicId}
              image={image}
              onRemove={remove}
            />
          ))}
        </div>
      ) : null}

      {!images && !isUploading ? (
        <label htmlFor="images">
          <button
            type="button"
            className={cn("flex items-center text-gray-500")}
            onClick={handleClick}
          >
            <div className="flex relative items-center mr-2">
              <dl>
                <dt>
                  <Image
                    src={LandscapeImage as StaticImageData}
                    alt=""
                    aria-hidden="true"
                  />
                </dt>
                <dd className="sr-only">Upload Image</dd>
              </dl>
            </div>
          </button>

          <input
            ref={ref}
            type="file"
            accept="image/jpeg, image/jpg, image/png, image/svg"
            className="hidden"
            onChange={handleChange}
            multiple
          />
        </label>
      ) : null}

      {isUploading ? (
        <div className="flex items-center">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default UploadImage;
