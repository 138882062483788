import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

import transformPaginatedPosts from "../transformers/transformPaginatedPosts";
import { ApiPaginatedPosts, PaginatedPosts } from "../types";

export const getPublicPostsQueryKey = (
  segmentId?: string,
  subtype?: string
): string[] => ["public-posts", segmentId, subtype].filter(Boolean);

export const getPublicPosts = (
  segmentId?: string,
  subtype?: string,
  signal?: AbortSignal
): Promise<PaginatedPosts> => {
  // build the query for the request
  // note :: if the segment id isn't passed, select the new feed
  const query = Object.entries({
    segment_id: segmentId,
    // filters: "my_topics",
    limit: 20,
    subtype,
  })
    .filter(([_, value]) => !!value)
    .map((item) => item.join("="))
    .join("&");

  return api
    .get<ApiPaginatedPosts>(`/v3/posts?${query}`, { signal })
    .then(({ data: page }) => transformPaginatedPosts(page));
};

export const usePublicPosts = (
  segmentId?: string,
  subtype?: string
): UseQueryResult<PaginatedPosts, AxiosError> => {
  return useQuery({
    queryKey: getPublicPostsQueryKey(segmentId, subtype),
    queryFn: ({ signal }) => getPublicPosts(segmentId, subtype, signal),
  });
};
