import { FC } from "react";

import PersonalizedFeed from "./PersonalizedFeed";
import PublicFeed from "./PublicFeed";

interface Props {
  isAuthenticated?: boolean;
  subtypeId?: string;
  segmentId?: string;
  setError: (error: Error) => void;
  subtypeSlug?: string;
}

const Feed: FC<Props> = ({
  isAuthenticated,
  subtypeId,
  segmentId,
  setError,
  subtypeSlug,
}) => {
  if (isAuthenticated) {
    return <PersonalizedFeed subtypeId={subtypeId} setError={setError} />;
  }

  return (
    <PublicFeed
      segmentId={segmentId}
      subtypeId={subtypeId}
      setError={setError}
      subtypeSlug={subtypeSlug}
    />
  );
};

export default Feed;
