import { FC } from "react";

import PostSkeleton from "../PostSkeleton";

const LoadingFeed: FC = () => {
  return (
    <div className="space-y-3">
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
    </div>
  );
};

export default LoadingFeed;
